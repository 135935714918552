import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import NothingToShow from "../../components/NothingToShow";
import { getTraits } from "../../service";
// import { traitTypeMartrix } from "../../utils/NFTData";

const Matrix = () => {
  const [traits, setTraits] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const data = await getTraits();
        console.log(data);
        setLoading(false);
        if (!data.ok) return;
        setTraits(data.data.traits);
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);
  return (
    <div className="text-white container">
      <div className="max-w-[800px] mx-auto mt-10">
        {/* <div className="text-center border-b pb-10">
          <p className="font-bold text-3xl uppercase italic">Total</p>
          <p className="font-bold text-4xl uppercase italic mt-10">1000</p>
        </div> */}
        <p className="font-bold text-3xl uppercase  mt-8 text-center">
          TRAIT TYPE MATRIX
        </p>
        {loading ? (
          <Loader />
        ) : (
          <div className="grid grid-cols-1 gap-10 mt-10">
            {traits.length > 0 ? (
              traits.map((val, i) => (
                <div key={i}>
                  <p className="text-2xl capitalize">{val.trait_type}</p>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4 text-center">
                    {val.values.map((value, index) => (
                      <div className="bg-dark p-4 rounded-xl " key={index}>
                        {/* <p className="text-lg font-semibold mb-1 capitalize">
                          {val.trait_type}
                        </p> */}
                        <p>{value.value}</p>

                        <p>
                          {Number(value.percentile).toFixed(2)}% have this trait
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <NothingToShow />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Matrix;
