import { useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Button from "../../../components/Button";
import StakeModal from "../../../components/StakeModal";
import { WalletModalContext } from "../../../context/walletModalContext";
// import { masterAbi, masterAddress } from "../../../web3/contracts/master";
import {
  approveNft,
  formatFromWei2,
  getContract,
  getTokenBalance,
  getTokenDetails,
  // transferNFTs,
} from "../../../web3/helpers";
import {
  CHAINLIST,
  getMostRareNFT,
  getNftUrl,
  getRarityString,
} from "../../../utils";
import { getNftById } from "../../../service";
// import { transferAddresses } from "../../../web3/contracts/transferContract";
import { NFT_Abi, NFT_Addresses } from "../../../web3/contracts/nft";
import { ChainChangeContext } from "../../../context/chainChangeContext";
import Loader from "../../../components/Loader";
const Stake = ({ data, address, abi, stakeData }) => {
  const { account, library, chainId } = useWeb3React();
  // const account = "0x3de2894d63334dB3a283b759b6d94491Fe5a3Da7";
  const [input, setInput] = useState("");
  const [holdingNfts, setHoldingNfts] = useState(0);
  const [baseTokenAddress, setBaseTokenAddress] = useState("");
  const [open, setOpen] = useState(false);
  const [isHaveNft, setIsHaveNft] = useState(false);
  const { connectHandler } = useContext(WalletModalContext);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [tokenDetails, setTokenDetails] = useState({});
  const [maxStakable, setMaxStakable] = useState(0);
  const [apy, setApy] = useState(0);
  const [maxStakablePerNft, setMaxStakablePerNft] = useState(0);
  const [nftRarity, setNftRarity] = useState(0);
  const [holdingNFTlist, setHoldingNFTlist] = useState([]);
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [mostRare, setMostRare] = useState({});
  const { chain } = useContext(ChainChangeContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      if (account) {
        const { ok, contract } = await getContract(library, address, abi);
        const { contract: nftContract } = await getContract(
          library,
          NFT_Addresses[chainId],
          NFT_Abi
        );
        if (!ok) {
          return;
        }

        try {
          // setLoading(true);
          const baseTokenAddress = await contract.methods
            .baseTokenAddress()
            .call();
          // console.log(baseTokenAddress);
          setBaseTokenAddress(baseTokenAddress);
          const tokenBalance = await getTokenBalance(
            library,
            baseTokenAddress,
            account
          );
          setTokenBalance(tokenBalance);
          const tokenDetails = await getTokenDetails(library, baseTokenAddress);
          setTokenDetails(tokenDetails);
          const maxStakableTokens = await contract.methods
            .getMaxStakableTokens(account)
            .call();
          setMaxStakable(
            formatFromWei2(maxStakableTokens, tokenDetails.decimals)
          );
          const holdingNfts = await nftContract.methods
            .getTokenIds(account)
            .call();
          // console.log(tokenBalance);
          // console.log(holdingNfts);
          const rareNFT = await getMostRareNFT(holdingNfts);
          setHoldingNfts(rareNFT?.countId ? rareNFT?.countId : 0);
          setIsHaveNft(holdingNfts.length > 0);
          setNftRarity(rareNFT?.rarity_value ? rareNFT?.rarity_value : 0);
          setHoldingNFTlist(holdingNfts);
          if (mostRare.id) {
            const apy = await contract.methods
              .getAPY(+mostRare.rarity, Number(data.duration))
              .call();
            console.log(+mostRare.rarity, Number(data.duration));
            console.log("apy", apy);
            setApy(apy);
          } else {
            const apy = await contract.methods
              .getAPY(
                rareNFT?.rarity_value ? rareNFT?.rarity_value : 0,
                Number(data.duration)
              )
              .call();
            setApy(apy);
          }
          const maxStakablePerNft = await contract.methods
            .maxStakableTokensPerNFT()
            .call();
          setMaxStakablePerNft(
            formatFromWei2(maxStakablePerNft, tokenDetails.decimals)
          );
        } catch (error) {
          console.log(error);
          // setLoading(false);
        }
        setLoading(false);
      }
    };
    getData();
  }, [abi, account, address, chainId, data, library, mostRare, stakeData]);
  // console.log(address);
  // console.log(mostRare);
  return loading ? (
    <Loader />
  ) : (
    <div className="md:p-4 mt-7">
      <div className="grid grid-cols-1 ">
        {(chain === CHAINLIST.BSCT || chain === CHAINLIST.BSC) && (
          <>
            <p className="text-center mb-2 font-semibold">
              <span>
                Your max stakable amount is {maxStakable} (1 NFT ={" "}
                {maxStakablePerNft} {tokenDetails?.symbol})
              </span>
              <br />
              <Link to="/mint" className="text-xs text-primary">
                Increase Your limit
              </Link>
            </p>
          </>
        )}
        {(chain === CHAINLIST.BSCT || chain === CHAINLIST.BSC) &&
        +maxStakable > 0 ? (
          <p className="text-center text-sm mb-3">
            {" "}
            Applied Rarity: {getRarityString(+nftRarity)}
          </p>
        ) : null}
        {(chain === CHAINLIST.POLYGONT || chain === CHAINLIST.ETH) &&
        holdingNFTlist.length > 0 ? (
          <>
            <p className="text-center mb-4 max-w-[400px] mx-auto ">
              Select NFTs you want to stake. From selected NFTs highest rarity
              APY should be applied
            </p>
            <NftList
              selectedNFTs={selectedNFTs}
              setSelectedNFTs={setSelectedNFTs}
              holdingNFTlist={holdingNFTlist}
              setMostRare={setMostRare}
              mostRare={mostRare}
              address={address}
            />
          </>
        ) : (
          (chain === CHAINLIST.POLYGONT || chain === CHAINLIST.ETH) && (
            <p className="text-center mb-4 max-w-[400px] mx-auto">
              You Dont have any NFTs
            </p>
          )
        )}{" "}
        <div className=" w-full max-w-[500px] mx-auto">
          {(chain === CHAINLIST.BSCT || chain === CHAINLIST.BSC) && (
            <>
              <div className="flex mb-1 justify-between w-full text-xs md:text-sm">
                <p>APY applied: {+maxStakable > 0 ? apy : "0.00"}%</p>
                <p className="text-right">
                  Balance: {tokenBalance} {tokenDetails.symbol}
                </p>
              </div>
              <div className=" relative ">
                <button
                  onClick={() =>
                    setInput(
                      +maxStakablePerNft > +tokenBalance
                        ? +tokenBalance
                        : +maxStakablePerNft
                    )
                  }
                  className=" bg-primary py-1 px-2 rounded-md absolute top-1/2 -translate-y-1/2 right-2 text-black text-xs"
                >
                  Max
                </button>
                <input
                  type="number"
                  name=""
                  id=""
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  className=" bg-transparent border rounded-lg w-full  block mx-auto border-white focus:ring-0 focus:border-white"
                  placeholder="Enter stake amount"
                />
              </div>
            </>
          )}
          {(chain === CHAINLIST.POLYGONT || chain === CHAINLIST.ETH) &&
            selectedNFTs.length > 0 && (
              <div className="text-center mt-4 mb-6">
                <p>
                  Required Tokens to staking:{" "}
                  {maxStakablePerNft * selectedNFTs.length} {""}
                  {tokenDetails.symbol}
                </p>
                <p>
                  Available {tokenDetails.symbol} balance: {""}
                  {tokenBalance}
                </p>
              </div>
            )}
          {selectedNFTs.length > 0 && (
            <div className="flex  justify-between w-full text-xs md:text-sm ">
              <p>APY applied: {+maxStakable > 0 ? apy : "0.00"}%</p>
              <p className="text-center text-sm mb-3">
                {" "}
                Applied Rarity: {getRarityString(mostRare.rarity)}
              </p>
            </div>
          )}
          {(chain === CHAINLIST.BSCT || chain === CHAINLIST.BSC) && <br />}
          <div className=" block mx-auto ">
            <Button
              rounded
              disable={
                (chain === CHAINLIST.POLYGONT || chain === CHAINLIST.ETH) &&
                holdingNFTlist.length === 0
              }
              fullWidth
              sm
              center
              onClick={() => {
                // console.log(address);
                // console.log(holdingNfts);
                if (!account) {
                  return connectHandler();
                }
                if (!isHaveNft) {
                  return toast.error("You dont have NFT!");
                }
                if (+maxStakablePerNft < input) {
                  return toast.error("Amount exceeds maximum stakable amount!");
                }
                if (
                  (chain === CHAINLIST.POLYGONT || chain === CHAINLIST.ETH) &&
                  maxStakablePerNft * selectedNFTs.length > tokenBalance
                ) {
                  return toast.error("You dont have enough balance");
                }
                if (
                  (chain === CHAINLIST.POLYGONT || chain === CHAINLIST.ETH) &&
                  selectedNFTs.length === 0
                ) {
                  return toast.error("Please select at least one NFT");
                }
                if (
                  (chain === CHAINLIST.BSCT || chain === CHAINLIST.BSC) &&
                  (!input || input <= 0)
                ) {
                  return toast.error("Please Enter a valid amount");
                }
                setOpen(true);
              }}
            >
              {account ? "Stake" : "Connect Wallet"}
            </Button>
          </div>
          {/* <div className=" mx-auto  ">
              <Button
                sm
                rounded
                fullWidth
                onClick={() =>
                  transferNFTs(
                    library,
                    selectedNFTs,
                    transferAddresses[chainId],
                    NFT_Addresses[chainId]
                  )
                }
              >
                {" "}
                Transfer ALL
              </Button>
            </div> */}
        </div>
      </div>
      <StakeModal
        open={open}
        handleClose={() => setOpen(false)}
        selectedNft={holdingNfts}
        selectedNFTs={selectedNFTs}
        poolId={data.id}
        value={
          chain === CHAINLIST.POLYGONT || chain === CHAINLIST.ETH
            ? maxStakablePerNft * selectedNFTs.length
            : input
        }
        tokenAddress={baseTokenAddress}
        address={address}
        abi={abi}
      />
    </div>
  );
};

export default Stake;

const NftList = ({
  holdingNFTlist,
  selectedNFTs,
  setSelectedNFTs,
  setMostRare,
  address,
}) => {
  useEffect(() => {
    const sortedByMostRare = selectedNFTs.sort((a, b) => b.rarity - a.rarity);
    if (sortedByMostRare.length > 0) setMostRare(sortedByMostRare[0]);
    // console.log(sortedByMostRare);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNFTs]);

  const nftSelectHandler = (selectedId, rarity) => {
    const ifExist = selectedNFTs.filter((nft) => nft.id === selectedId);
    if (ifExist.length > 0) {
      let filteredNFTs = selectedNFTs.filter((nft) => nft.id !== selectedId);
      setSelectedNFTs(filteredNFTs);

      return;
    }
    setSelectedNFTs((prev) => [...prev, { id: selectedId, rarity }]);
  };

  return (
    <div className="grid grid-cols-2  sm:grid-cols-6  lg:grid-cols-12 gap-2 mb-3">
      {holdingNFTlist.map((nft, i) => (
        <React.Fragment key={i}>
          <NftCard
            id={nft}
            selectedNFTs={selectedNFTs}
            nftSelectHandler={nftSelectHandler}
            address={address}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

const NftCard = ({ id, selectedNFTs, nftSelectHandler, address }) => {
  const [rarity, setRarity] = useState("");
  const { account, library, chainId } = useWeb3React();
  const [approved, setApproved] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getNftById(id);
        if (!data.ok) return;
        setRarity(data.data.nft.rarity_value);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [id]);
  const getData = async () => {
    try {
      const { ok, contract } = await getContract(
        library,
        NFT_Addresses[chainId],
        NFT_Abi
      );

      if (!ok) {
        return;
      }

      const approvedAdd = await contract.methods.getApproved(id).call();
      if (approvedAdd.toLowerCase() === address.toLowerCase()) {
        setApproved(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, id, library]);
  // useEffect()

  const isSelected = (id) => {
    const selected = selectedNFTs.filter((nft) => nft.id === id);
    return selected.length > 0;
  };
  return (
    <div className="w-full">
      <button
        className={` relative border-4 rounded-md overflow-hidden  ${
          isSelected(id) ? "border-primary" : "border-transparent"
        }`}
        onClick={() => {
          if (!approved) {
            return toast.error("Please approve!");
          }
          nftSelectHandler(id, rarity);
        }}
      >
        <p className=" absolute px-2 py-0.5 rounded-full  text-[10px] text-black right-1 top-1 bg-primary">
          #{id}
        </p>
        <img src={getNftUrl(id)} className="" alt="" />
      </button>
      <p className="text-xs text-center">{getRarityString(rarity)}</p>
      <button
        className={` ${
          approved ? " pointer-events-none bg-gray-400" : "bg-primary"
        } block mx-auto text-xs  px-2 py-1 rounded-full text-black`}
        onClick={() => {
          approveNft(library, id, address, NFT_Addresses[chainId], () =>
            setApproved(true)
          );
        }}
      >
        {approved ? "Approved" : "Approve"}
      </button>
    </div>
  );
};
