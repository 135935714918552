import { Collapse } from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import Button from "../../../components/Button";
import { contractAddLink, staticApys } from "../../../utils";
import { contractAbis } from "../../../web3/contracts/primary";
import {
  formatFromWei2,
  getContract,
  getTokenDetails,
} from "../../../web3/helpers";
import Pool from "./Pool";
import { RPC_URLS } from "../../../web3/wallet/connector";
import { ChainChangeContext } from "../../../context/chainChangeContext";
import Token from "./Token";

const StakeShibGetShib = ({ stakes }) => {
  const { account, library, chainId } = useWeb3React();
  const [open, setOpen] = useState(false);
  const [pools, setPools] = useState([]);
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [userStakes, setUserStakes] = useState([]);
  const [rewardTokenAddress, setRewardTokenAddress] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [usersTotalStaked, setUsersTotalStaked] = useState(0);
  const [usersTotalClaimed, setUsersTotalClaimed] = useState(0);
  // const [baseTokenAddress, setBaseTokenAddress] = useState("");
  const [tokenDetails, setTokenDetails] = useState({});
  const { chain } = useContext(ChainChangeContext);
  // const [allApy, setAllApy] = useState({});
  // useEffect(() => {
  //   const getData = async () => {
  //     const provider = new Web3(
  //       new Web3.providers.HttpProvider(RPC_URLS[chain])
  //     );
  //     if (provider) {
  //       const { ok, contract } = await getContract(
  //         provider,
  //         stakes[0],
  //         contractAbis[chain]
  //       );
  //       // console.log(contract);

  //       if (!ok) {
  //         return;
  //       }

  //       try {
  //         const allPools = await contract.methods.getAllPoolDetails().call();
  //         setPools(allPools);

  //         const baseTokenAddress = await contract.methods
  //           .baseTokenAddress()
  //           .call();
  //         // setBaseTokenAddress(baseTokenAddress);
  //         const tokenDetails = await getTokenDetails(
  //           provider,
  //           baseTokenAddress
  //         );
  //         setTokenDetails(tokenDetails);
  //         const amountStaked = await contract.methods
  //           .totalStakedBalance()
  //           .call();
  //         const convertedStakedValue = formatFromWei2(
  //           amountStaked,
  //           tokenDetails.decimals
  //         );
  //         setTotalStaked(convertedStakedValue);
  //         const amountClaimed = await contract.methods
  //           .totalClaimedBalance()
  //           .call();
  //         const convertedAmountClamied = formatFromWei2(
  //           amountClaimed,
  //           tokenDetails.decimals
  //         );
  //         setTotalClaimed(convertedAmountClamied);
  //         const rewardTokenAddress = await contract.methods
  //           .rewardTokensAddress()
  //           .call();
  //         setRewardTokenAddress(rewardTokenAddress);
  //       } catch (error) {
  //         console.log(error);
  //         // setLoading(false);
  //       }
  //     }
  //   };
  //   getData();
  // }, [account, chain, library, stakes, chainId]);
  // useEffect(() => {
  //   const getUserStakes = async () => {
  //     if (account) {
  //       const { ok, contract } = await getContract(
  //         library,
  //         stakes[0],
  //         contractAbis[chain]
  //       );

  //       if (!ok) {
  //         return;
  //       }
  //       try {
  //         const userStakes = await contract.methods
  //           .getUserAllStakeDetailsByAddress(account)
  //           .call();
  //         // console.log(userStakes);
  //         setUserStakes(userStakes);
  //         // console.log("USER STAKED", userStakes);
  //         const users = await contract.methods.users(account).call();
  //         setUsersTotalStaked(
  //           formatFromWei2(users.totalStakedBalance, tokenDetails.decimals)
  //         );
  //         setUsersTotalClaimed(
  //           formatFromWei2(users.totalClaimedRewards, tokenDetails.decimals)
  //         );
  //         const owner = await contract.methods.owner().call();

  //         setIsAuthorized(owner.toLowerCase() === account.toLowerCase());
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   };
  //   getUserStakes();
  // }, [library, account, stakes, tokenDetails, chain, chainId]);

  return (
    <div className=" bg-dark rounded-xl p-6 ">
      <div className=" md:flex justify-between items-center">
        <div className="mb-3 md:mb-0">
          <p className=" text-xl md:text-2xl font-bold">
            Stake SHIBELON to earn SHIBELON
          </p>
          <p className="md:text-base text-sm">
            Stake $SHIBELON in one of our four core pools to start earning
            rewards.
          </p>
          {/* {isAuthorized && stakes[0] && (
            <a
              href={contractAddLink(stakes[0], chain)}
              target="_blank"
              rel="noreferrer"
              className="text-primary text-[10px] block mt-1"
            >
              View Contract
            </a>
          )} */}
        </div>
        <div className="grid grid-flow-col gap-2 ">
          {/* {isAuthorized && rewardTokenAddress && (
            <Button
              sm
              link
              to={`inject_reward/${stakes[0]}/${rewardTokenAddress}`}
            >
              Inject
            </Button>
          )} */}
          <Button sm onClick={() => setOpen(!open)}>
            View Stakes
          </Button>
        </div>
      </div>

      <Collapse in={open}>
        {/* {pools.length === 0 ? (
          <p className="text-center py-10 font-bold">
            No staking pools available yet
          </p>
        ) : ( */}

        {/* <div className="grid md:grid-flow-col gap-10 justify-center items-center my-7 text-center">
              <div className="">
                <p className=" font-bold text-xl"> Total SHIBELON staked</p>
                <p>{totalStaked}</p>
              </div>{" "}
              <div className="">
                <p className=" font-bold text-xl"> Total SHIBELON earned</p>
                <p>{totalClaimed}</p>
              </div>
            </div>
            <div className="grid md:grid-flow-col gap-10 justify-center items-center my-7 text-center">
              <div className="">
                <p className=" font-bold text-xl"> Your SHIBELON staked</p>
                <p>{usersTotalStaked}</p>
              </div>{" "}
              <div className="">
                <p className=" font-bold text-xl"> Your SHIBELON earned</p>
                <p>{usersTotalClaimed}</p>
              </div>
            </div> */}
        {/* <div className="py-5 grid grid-cols-1 gap-4">
              {pools.map((pool, index) => (
                <React.Fragment key={index}>
                  <Pool
                    data={pool}
                    address={stakes[0]}
                    abi={contractAbis[chain]}
                    userStakes={userStakes}
                    apy={staticApys[index]}
                  />
                </React.Fragment>
              ))}
            </div> */}
        <div className="py-5 grid grid-cols-1 gap-4">
          {stakes.length > 0 ? (
            stakes.map((token, index) => (
              <React.Fragment key={index}>
                <Token
                  address={token}
                  abi={contractAbis[chain]}
                  type={1}
                  index={index}
                />
              </React.Fragment>
            ))
          ) : (
            <p className=" font-semibold py-6 text-center">
              No staking pools available yet
            </p>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default StakeShibGetShib;
