import React, { useContext } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { MINT_LINK, openSeaLink, socialLinks } from "../../utils";
import { AiFillCaretDown } from "react-icons/ai";
import { Backdrop } from "@mui/material";
import { ChainChangeContext } from "../../context/chainChangeContext";
const Sidebar = ({ open, setOpen }) => {
  const { chain } = useContext(ChainChangeContext);

  const sidebarMenu = [
    {
      name: "MINT",
      to: MINT_LINK,
    },
    {
      name: "MY NFTS",
      to: "my_nfts",
    },
    {
      name: "STAKING POOLS",
      to: "",
    },
    {
      name: "NFTS",
      to: "nfts",
      submenu: [
        {
          name: "Rarity",
          to: "/rarity",
        },
        {
          name: "OpenSea",
          to: openSeaLink[chain],
          link: true,
        },
        {
          name: "Matrix",
          to: "/matrix",
        },
      ],
    },
  ];
  return (
    <>
      <Backdrop open={open} onClick={() => setOpen(false)}></Backdrop>
      <div
        className={`z-[9999] ${
          open ? "left-0" : "-left-full lg:left-0"
        } bg-[#131313] transition-all duration-300 fixed h-full w-[250px] p-4 text-white flex flex-col justify-between`}
      >
        <div>
          <div className="">
            <Link
              to={`/?chain=${chain}`}
              className="grid grid-flow-col gap-2 justify-start items-center"
            >
              <img src="/assets/logo.png" className="w-16" alt="" />
            </Link>
          </div>
          <div className="mt-10 flex-1">
            <ul>
              {sidebarMenu.map((item, index) => (
                <li key={index}>
                  {item.link ? (
                    <a
                      className="p-3 px-4 hover:bg-[#020202] rounded-xl flex justify-between items-center mb-2"
                      href={item.to}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.name}
                    </a>
                  ) : (
                    <CustomLink
                      to={`${item.to}/?chain=${chain}`}
                      onClick={(e) => {
                        if (item.submenu?.length > 0) {
                          return e.preventDefault();
                        }
                        setOpen(false);
                      }}
                      className="p-3 px-4 hover:bg-[#020202] rounded-xl flex justify-between items-center mb-2"
                    >
                      <p>{item.name}</p>
                      {item.submenu?.length > 0 && <AiFillCaretDown />}
                    </CustomLink>
                  )}

                  {item.submenu?.length > 0 && (
                    <ul className="ml-2">
                      {item.submenu.map((submenuItem, subindex) => (
                        <li key={subindex}>
                          {submenuItem.link ? (
                            <a
                              className="p-2 px-4 hover:bg-[#020202] rounded-xl flex justify-between items-center mb-2"
                              href={submenuItem.to}
                              // to={submenuItem.to}
                              target="_blank"
                              onClick={(e) => {
                                setOpen(false);
                              }}
                              rel="noreferrer"
                            >
                              {submenuItem.name}
                            </a>
                          ) : (
                            <CustomLink
                              className="p-2 px-4 hover:bg-[#020202] rounded-xl flex justify-between items-center mb-2"
                              to={`${submenuItem.to}/?chain=${chain}`}
                              // to={submenuItem.to}
                              onClick={(e) => {
                                setOpen(false);
                              }}
                            >
                              {submenuItem.name}
                            </CustomLink>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <ul className=" grid grid-flow-col gap-4  justify-center  items-center mt-4 mb-2">
            {socialLinks.map((val, index) => (
              <li key={index}>
                <a
                  href={val.link}
                  className="text-primary text-lg"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {val.icon}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

function CustomLink({ children, to, className, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <>
      <Link
        className={` transition-all duration-300 ${
          match ? " bg-[#020202]   " : "text-[#dde3e3]"
        } ${className}`}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </>
  );
}
