import { useWeb3React } from "@web3-react/core";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ChainChangeModal from "../components/ChainChangeModal";
import { chains } from "../components/ChainChangeModal/chaindata";
import { WalletModalContext } from "./walletModalContext";

export const ChainChangeContext = createContext();
const defaultValue = {
  icon: "",
  name: "",
  chain: 0,
  disabled: false,
  symbol: "",
};
const ChainChangeProvider = ({ children }) => {
  const [searchParams] = useSearchParams();
  // const navigate = useNavigate();
  const routeChain = searchParams.get("chain");
  const { chainId, account } = useWeb3React();
  const [currentChain, setCurrentChain] = useState(defaultValue);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { connecting } = useContext(WalletModalContext);
  useEffect(() => {
    const updateChain = () => {
      // if (chainId) {
      //   const selectedChain = chains.filter((chain) => chain.chain === chainId);
      //   selectedChain.length !== 0
      //     ? setCurrentChain(selectedChain[0])
      //     : setCurrentChain(chains[0]);
      //   // console.log(chains.filter((chain) => chain.chain === chainId));
      // }
      // if (!account) {
      //   if (!connecting) setCurrentChain(chains[1]);
      // }
      if (routeChain && !chainId) {
        const selectedChain = chains.filter(
          (chain) => chain.chain === +routeChain
        );
        selectedChain.length !== 0
          ? setCurrentChain(selectedChain[0])
          : setCurrentChain(chains[0]);
        // switchChainOnRoute(routeChain);
        // console.log(chains.filter((chain) => chain.chain === chainId));
      } else if ((routeChain && chainId) || (!routeChain && chainId)) {
        const selectedChain = chains.filter(
          (chain) => chain.chain === +chainId
        );
        selectedChain.length !== 0
          ? setCurrentChain(selectedChain[0])
          : setCurrentChain(chains[0]);
        // switchChainOnRoute(chainId);
      } else {
        setCurrentChain(chains[1]);
        // switchChainOnRoute(chains[3].chain);
      }
    };
    updateChain();
  }, [account, chainId, connecting, routeChain]);
  return (
    <ChainChangeContext.Provider
      value={{ openChainModal: handleOpen, currentChain, ...currentChain }}
    >
      {children}
      <ChainChangeModal
        open={open}
        setOpen={setOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        currentChain={currentChain}
        setCurrentChain={setCurrentChain}
      />
    </ChainChangeContext.Provider>
  );
};

export default ChainChangeProvider;
