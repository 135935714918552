import { useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { ChainChangeContext } from "../../context/chainChangeContext";

import { MINT_LINK } from "../../utils";
import { masterAbi, masterAddresses } from "../../web3/contracts/master";
import { NFT_Abi, NFT_Addresses } from "../../web3/contracts/nft";
import { getContract } from "../../web3/helpers";
import Nft from "./Nft";

const NftBox = () => {
  const [totalNfts, setTotalNfts] = useState([]);
  const { account, library, chainId } = useWeb3React();
  const [stakedNfts, setStakedNfts] = useState([]);
  const { currentChain } = useContext(ChainChangeContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getDeatilsStaking = async () => {
      if (!account) return;
      const { ok, contract } = await getContract(
        library,
        NFT_Addresses[currentChain.chain],
        NFT_Abi
      );
      const { ok: masterOk, contract: masterContract } = await getContract(
        library,
        masterAddresses[currentChain.chain],
        masterAbi
      );
      if (!ok || !masterOk) {
        return;
      }

      try {
        setLoading(true);
        const nfts = await contract.methods.getTokenIds(account).call();
        console.log("NFTS", nfts);
        let stakedNfts = await masterContract.methods
          .getUserStakedNFTs(account)
          .call();
        stakedNfts = stakedNfts.filter((val) => !nfts.includes(val));
        stakedNfts = Array.from(new Set(stakedNfts));
        setTotalNfts(nfts);
        setStakedNfts(stakedNfts);
        console.log(stakedNfts, nfts);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    getDeatilsStaking();
  }, [account, currentChain, library, chainId]);
  return loading ? (
    <div className="min-h-screen justify-center flex">
      <Loader />
    </div>
  ) : (
    <div className="">
      {totalNfts.length + stakedNfts.length > 0 ? (
        <div className="">
          <h2 className="text-xl font-bold">Your NFTs</h2>
          <p className="mb-2  mt-4">
            {" "}
            NFTs Owned: {totalNfts.length + stakedNfts.length}
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-7">
            {totalNfts.length > 0 ? (
              totalNfts.map((item, index) => (
                <React.Fragment key={index}>
                  <Nft id={item} />
                </React.Fragment>
              ))
            ) : (
              <></>
            )}{" "}
            {stakedNfts.length > 0 ? (
              stakedNfts.map((item, index) => (
                <React.Fragment key={index}>
                  <Nft id={item} staked={true} />
                </React.Fragment>
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className=" p-4 mt-10 text-center py-6">
          <p>You dont have any NFTs!</p>
          <br />
          <a
            className="py-2 text-black px-6 rounded-full bg-primary "
            href={MINT_LINK}
            target="_blank"
            rel=" noreferrer"
          >
            Mint NFT
          </a>
        </div>
      )}
    </div>
  );
};

export default NftBox;
