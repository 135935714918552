import { useWeb3React } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import Button from "../../../components/Button";
import LockedWarningModal from "../../../components/LokedWarningModal";
import Timer from "../../../components/Timer";
import {
  claim,
  formatFromWei,
  getContract,
  reStake,
  unStake,
} from "../../../web3/helpers";

const StakeDetailBox = ({ data }) => {
  const [unclaimedBalance, setUnclaimedBalance] = useState(0);
  const { library, account } = useWeb3React();
  const [open, setOpen] = useState(false);
  //   const
  useEffect(() => {
    const getData = async () => {
      const { ok, contract } = await getContract(library);
      if (!ok) {
        return;
      }
      try {
        const unclaimedBalance = await contract.methods
          .getUnclaimedRewards(data.id)
          .call({ from: account });

        setUnclaimedBalance(formatFromWei(unclaimedBalance));
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [account, library, data]);
  let isAbletoUnstake = Number(data.unlockTime) * 1000 - Date.now();
  isAbletoUnstake = isAbletoUnstake < 0;
  const handleUnstake = () => {
    unStake(library, data.id);
    setOpen(false);
  };
  return (
    <div className="  text-sm text-center bg-[#2d2d2d] shadow-xl rounded-xl p-4 grid grid-cols-1 gap-2">
      <div className="text-center mt-1 flex items-center justify-between">
        <p>Staked NFT:</p>
        <div className="mt-1 grid grid-flow-col gap-2 items-center">
          <p>#{data.nftTokenId} </p>
          <img
            src={`https://ipfs.io/ipfs/bafybeickm6g2642mptchejj7hodh4wqexoa6k5prwxt2csgvqay3vemhpm/${data.nftTokenId}.png`}
            alt=""
            className="rounded-full w-8"
          />
        </div>
      </div>{" "}
      <div className="text-center mt-1 flex items-center justify-between">
        <p>STAKED:</p>
        <p className="mt-1">{formatFromWei(data.stakeBalance)} BUSD </p>
      </div>{" "}
      <div className="text-center mt-1 flex items-center justify-between">
        <p>DURATION:</p>
        <p className="mt-1">{data.lockDuration} Days </p>
      </div>{" "}
      <div className="text-center mt-1 flex items-center justify-between">
        <p>STATUS:</p>
        <p className="text-xs font-bold">
          {Number(data.status) === 1 ? (
            <span className="text-white  block px-2 py-1  bg-green-700  rounded-full">
              Active
            </span>
          ) : (
            <span className="text-white block px-2 py-1 bg-red-700  rounded-full">
              Unstaked
            </span>
          )}
        </p>
      </div>
      <div className="text-center mt-1 flex items-center justify-between">
        <p className="uppercase">REWARDS:</p>
        <p>{formatFromWei(data.totalClaimedRewards)} BUSD</p>
      </div>{" "}
      <div className="text-center mt-1 flex items-center justify-between">
        <p>CLAIMABLE REWARDS:</p>
        <p>{Number(data.status) === 1 ? unclaimedBalance : 0} BUSD</p>
      </div>
      <div className="text-center mt-1 flex items-center justify-between">
        <p>CLAIM TIME:</p>
        <p>
          {Number(data.status) === 1 ? (
            <Timer timestamp={data.unlockTime} />
          ) : (
            "---"
          )}
        </p>
      </div>
      <div className=" mt-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <Button
            sm
            disable={!isAbletoUnstake || Number(data.status) === 0}
            onClick={() => {
              if (!isAbletoUnstake) {
                return toast.error(" You can`t claim before unlock time.");
              }
              claim(library, data.id);
            }}
          >
            {" "}
            Claim
          </Button>

          <Button
            sm
            disable={Number(data.status) === 0}
            onClick={() => {
              if (Number(data.lockDuration) > 1) {
                return setOpen(true);
              }
              //   unStake(library, data.id);
            }}
          >
            Unstake
          </Button>

          {Number(data.status) === 1 &&
            Number(data.lockDuration) > 1 &&
            isAbletoUnstake && (
              <Button
                sm
                disable={!isAbletoUnstake || Number(data.status) === 0}
                onClick={() => {
                  reStake(library, data.id);
                }}
              >
                ReStake
              </Button>
            )}
        </div>
      </div>
      <LockedWarningModal
        open={open}
        setOpen={setOpen}
        handleUnstake={handleUnstake}
      />
    </div>
  );
};

export default StakeDetailBox;
