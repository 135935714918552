// import { ClickAwayListener } from "@mui/material";
import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Header setOpen={setOpen} />
      <div className="flex bg-[#000]">
        <div className="lg:min-w-[250px]">
          <Sidebar open={open} setOpen={setOpen} />{" "}
        </div>
        <div className="min-h-screen bg-gray flex flex-col flex-1">
          <div className="  flex-1 max-w-full mt-20">
            <>{children}</>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
