import React from "react";
import NftBox from "./NftBox";

const Nfts = ({ nfts }) => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {nfts.map((val, i) => (
          <React.Fragment key={i}>
            <NftBox val={val} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Nfts;
