import React from "react";

const NothingToShow = () => {
  return (
    <div className="min-h-[400px] flex justify-center items-center">
      <p>Nothig to show here</p>
    </div>
  );
};

export default NothingToShow;
