import { useWeb3React } from "@web3-react/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../../components/Button";
import { contractAbis } from "../../web3/contracts/primary";
import { approveAndInject } from "../../web3/helpers";
const InjectionSupply = () => {
  const { contractAddress, tokenAddress } = useParams();
  const [tokens, setTokens] = useState(0);
  const { library, chainId } = useWeb3React();
  return (
    <div className="text-white px-6 pt-6">
      {/* {contractAddress}
      {tokenAddress} */}

      <div>
        <div className=" max-w-sm mx-auto w-full p-4 mt-10 text-center">
          <p className="text-xl uppercase font-bold">Inject Token</p>
          <input
            type="number"
            className="py-2 px-4 w-full mt-3 focus:outline-none text-white text-xl rounded-xl bg-transparent mb-4"
            onChange={(e) => setTokens(e.target.value)}
          />
          <Button
            center
            onClick={() =>
              approveAndInject(
                library,
                tokens,
                contractAddress,
                tokenAddress,
                contractAbis[chainId]
              )
            }
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InjectionSupply;
