import React from "react";
import IndividualStakeDetailBox from "./IndividualStakeDetailBox";

const AllStakes = ({ userStakes, address, abi }) => {
  return (
    <div>
      <p className="text-xl font-bold mt-10">Your Stakes</p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-5">
        {userStakes.map((stake, i) => (
          <React.Fragment key={i}>
            <IndividualStakeDetailBox
              stake={stake}
              address={address}
              abi={abi}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default AllStakes;
