import { useWeb3React } from "@web3-react/core";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { WalletModalContext } from "../../context/walletModalContext";
// import { menuList } from "../../utils";
import { shortAddress } from "../../web3/helpers";
import Button from "../Button";
import { HiMenu } from "react-icons/hi";
import { ChainChangeContext } from "../../context/chainChangeContext";
// import Collapse from "@mui/material/Collapse";
const Header = ({ setOpen }) => {
  const { connectHandler } = useContext(WalletModalContext);
  const { account } = useWeb3React();
  const { currentChain, openChainModal } = useContext(ChainChangeContext);

  // const [open, setOpen] = useState(false);
  return (
    <div className="bg-dark fixed w-full lg:w-[calc(100%-250px)] right-0 z-[999]">
      <div className=" py-4 px-4 flex items-center lg:justify-end justify-between ">
        <Link
          to="/"
          className="grid grid-flow-col gap-2 justify-start items-center lg:hidden"
        >
          <img src="/assets/logo.png" className="w-16" alt="" />
        </Link>

        <ul className=" grid grid-flow-col gap-1 md:gap-3 items-center  text-white">
          <li>
            <Button rounded sm onClick={openChainModal}>
              <img src={currentChain.icon} alt="" className="w-5 h-5" />
              <p className="md:block hidden ">{currentChain.name}</p>
            </Button>
          </li>
          <li>
            <Button rounded sm onClick={connectHandler}>
              {account ? (
                shortAddress(account)
              ) : (
                <>
                  <span className="md:block hidden">Connect Wallet</span>
                  <span className="md:hidden">Connect</span>
                </>
              )}
            </Button>
          </li>
          <li>
            <button
              className="text-xl lg:hidden text-white"
              onClick={() => setOpen((prev) => !prev)}
            >
              <HiMenu />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
