import { FaGlobe, FaTwitter, FaTelegramPlane, FaDiscord } from "react-icons/fa";
import { getNftById } from "../service";

// export const MINT_LINK = "https://shibelon-mint-dev.netlify.app/";

export const MINT_LINK = "/mint";

export const getRarityString = (nftId) => {
  switch (nftId) {
    case 5:
      return "Legendary";
    case 4:
      return "Epic";
    case 3:
      return "Rare";
    case 2:
      return "Uncommon";
    case 1:
      return "Common";

    default:
      return "";
  }
};

export const staticApys = {
  0: "100% to 400%",
  1: "150% to 600%",
  2: "200% to 800%",
  3: "250% to 1000%",
};
export const menuList = [
  // {
  //   name: "Home",
  //   to: "/",
  // },
  // {
  //   name: "Rewards",
  //   to: "rewards",
  // },
];
export const sidebarMenu = [
  {
    name: "MINT",
    to: MINT_LINK,
  },
  {
    name: "MY NFTS",
    to: "my_nfts",
  },
  {
    name: "STAKING POOLS",
    to: "",
  },
  {
    name: "NFTS",
    to: "nfts",
    submenu: [
      {
        name: "Rarity",
        to: "/rarity",
      },
      {
        name: "Rarity",
        to: "/rarity",
      },
      {
        name: "Matrix",
        to: "/matrix",
      },
    ],
  },
];

export const socialLinks = [
  {
    title: "Website",
    icon: <FaGlobe />,
    link: "https://shibelon.net/",
  },
  {
    title: "Twitter",
    icon: <FaTwitter />,
    link: "https://twitter.com/shibelon_moon",
  },
  {
    title: "Telegram",
    icon: <FaTelegramPlane />,
    link: "https://t.me/shibelon_moon",
  },
  {
    title: "Discord",
    icon: <FaDiscord />,
    link: "https://discord.com/invite/kQP4jhPJ4U",
  },
];

export const applyRarites = {
  0: "None",
  1: "Common",
  2: "Uncommon",
  3: "Superstar",
  4: "Alpha",
  5: "Golden Santa",
};

// export const BASE_URL = "http://localhost:5000/api"; //LOCAL
export const BASE_URL = "https://shibelon-nft.onrender.com/api"; //LIVE

export const getNftUrl = (id) => {
  return `https://gentle-gingersnap-b45cfa.netlify.app/${id}.png`;
};
export const addressBaseLink = {
  56: "https://bscscan.com",
  97: "https://testnet.bscscan.com",
  80001: "https://mumbai.polygonscan.com",
  1: "https://etherscan.io",
};
export const contractAddLink = (address, chain) => {
  return `${addressBaseLink[chain]}/address/${address}`;
};

export const CHAINLIST = {
  POLYGONT: 80001,
  BSCT: 97,
  BSC: 56,
  ETH: 1,
};
export const getMostRareNFT = async (nfts) => {
  const allNfts = [];
  if (nfts.length === 0) return null;
  for (let i = 0; i < nfts.length; i++) {
    const nftId = nfts[i];
    // console.log(nftId);
    const data = await getNftById(nftId);
    // console.log(data);
    allNfts.push(data.data.nft);
  }

  const max = allNfts.sort((a, b) => Number(a.rank) - Number(b.rank));
  return max[0];
};

export const openSeaLink = {
  1: "https://opensea.io/collection/shibelon-eth",
  56: "https://opensea.io/collection/shibelon-bsc",
};
export const tokenData = {
  1: {
    1: {
      0: {
        baseLogo: "/assets/logo.png",
        rewardLogo: "/assets/logo.png",
        websiteLink: "https://shibelon.net/",
        telegramLink: "https://t.me/shibelon_moon",
        twitterLink: "https://twitter.com/shibelon_moon",
      },
    },
    2: {
      0: {
        baseLogo: "https://via.placeholder.com/150/585858/FFFFFF/?text=?",
        rewardLogo: "https://via.placeholder.com/150/585858/FFFFFF/?text=?",
        websiteLink: "",
        telegramLink: "",
        twitterLink: "",
      },
    },
    3: {
      0: {
        baseLogo: "/assets/kennel.jpg",
        rewardLogo: "/assets/kennel.jpg",
        websiteLink: "https://www.kennellocker.io",
        telegramLink: "http://t.me/kennellockerentry",
        twitterLink: "https://twitter.com/kennellocker",
      },
    },
  },
  56: {
    1: {
      0: {
        baseLogo: "/assets/logo.png",
        rewardLogo: "/assets/logo.png",
        websiteLink: "https://shibelon.net/",
        telegramLink: "https://t.me/shibelon_moon",
        twitterLink: "https://twitter.com/shibelon_moon",
      },
    },
    2: {
      0: {
        baseLogo: "",
        rewardLogo: "",
        websiteLink: "",
        telegramLink: "",
        twitterLink: "",
      },
    },
    3: {
      0: {
        baseLogo: "https://pepeki.net/assets/img/images/logo.png",
        rewardLogo: "https://pepeki.net/assets/img/images/logo.png",
        websiteLink: "https://pepeki.net/",
        telegramLink: "https://t.me/PepekiCoin",
        discordLink: "",
        twitterLink: "https://twitter.com/PepekiCoin",
      },
    },
  },
};

export const dexLinks = [
  {
    link: {
      1: "https://www.dextools.io/app/en/ether/pair-explorer/0xf5364c1a9c85c21c3496f1308feb79af48f17c87",
      56: "https://poocoin.app/tokens/0xc183062db25fc96325485ea369c979ce881ac0ea",
    },
    logo: "/assets/logo.png",
    symbol: "SHIBELON",
  },
  {
    link: {
      1: "https://www.dextools.io/app/en/ether/pair-explorer/0xa43fe16908251ee70ef74718545e4fe6c5ccec9f",
      56: "https://www.dextools.io/app/en/ether/pair-explorer/0xa43fe16908251ee70ef74718545e4fe6c5ccec9f",
    },
    logo: "https://pepeki.net/assets/img/images/logo.png",
    symbol: "PEPEKI",
  },
  {
    link: {
      1: "https://www.dextools.io/app/en/bnb/pair-explorer/0xdb79f966bf3ede1e6c1183c9fc3a67d740c2eee0",
      56: "https://www.dextools.io/app/en/bnb/pair-explorer/0xdb79f966bf3ede1e6c1183c9fc3a67d740c2eee0",
    },
    logo: "https://www.dextools.io/resources/tokens/logos/bsc/0x69c2fcae7e30b429166bd616a322e32bec036bcf.png?1684268612906",
    symbol: "MURATIAI",
  },
  {
    link: {
      1: "https://www.dextools.io/app/en/ether/pair-explorer/0xa43fe16908251ee70ef74718545e4fe6c5ccec9f",
      56: "https://www.dextools.io/app/en/ether/pair-explorer/0xa43fe16908251ee70ef74718545e4fe6c5ccec9f",
    },
    logo: "https://www.dextools.io/resources/tokens/logos/ether/0x6982508145454ce325ddbe47a25d4ec3d2311933.png?1681673107413",
    symbol: "PEPE",
  },
  {
    link: {
      1: "https://twitter.com/shibtoken",
      56: "https://twitter.com/shibtoken",
    },
    logo: "/assets/shibtoken.jpg",
    symbol: "SHIB",
  },
  {
    link: {
      1: "https://www.dextools.io/app/en/ether/pair-explorer/0xca7c2771d248dcbe09eabe0ce57a62e18da178c0",
      56: "https://www.dextools.io/app/en/ether/pair-explorer/0xca7c2771d248dcbe09eabe0ce57a62e18da178c0",
    },
    logo: "https://www.dextools.io/resources/tokens/logos/3/ether/0xcf0c122c6b73ff809c693db761e7baebe62b6a2e.png?1643184642",
    symbol: "FLOKI",
  },
  {
    link: {
      1: "https://www.dextools.io/app/en/ether/pair-explorer/0xbe8bc29765e11894f803906ee1055a344fdf2511",
      56: "https://www.dextools.io/app/en/ether/pair-explorer/0xbe8bc29765e11894f803906ee1055a344fdf2511",
    },
    logo: "https://www.dextools.io/resources/tokens/logos/ether/0x7d8146cf21e8d7cbe46054e01588207b51198729.jpeg?1681973402512",
    symbol: "BOB",
  },
  {
    link: {
      1: "https://coinmarketcap.com/currencies/bone-shibaswap/",
      56: "https://coinmarketcap.com/currencies/bone-shibaswap/",
    },
    logo: "/assets/bone.jpg",
    symbol: "BONE",
  },
  {
    link: {
      1: "https://tehbag.com/",
      56: "https://tehbag.com/",
    },
    logo: "/assets/tehbag.png",
    symbol: "TEHBAG",
  },
  {
    link: {
      1: "https://www.dextools.io/app/en/ether/pair-explorer/0xdce93ed9ae7c53143e19cf799d156b72d1cc2777",
      56: "https://www.dextools.io/app/en/ether/pair-explorer/0xdce93ed9ae7c53143e19cf799d156b72d1cc2777",
    },
    logo: "https://www.dextools.io/resources/tokens/logos/ether/0x9bf1d7d63dd7a4ce167cf4866388226eeefa702e.png?1683525927677",
    symbol: "BEN",
  },
  // {
  //   link: {
  //     1: "https://kennellocker.io",
  //     56: "https://kennellocker.io",
  //   },
  //   logo: "/assets/kennel.jpg",
  //   symbol: "KENNEL",
  // },
];
