import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getNftUrl } from "../../../utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
const NftBox = ({ val }) => {
  const [rarity, setRarity] = useState("");
  console.log(val);
  useEffect(() => {
    const rank20 = [
      2349, 738, 567, 2692, 1296, 1347, 1063, 83, 800, 1731, 1547, 2300, 2178,
      2694, 1360, 1400, 279, 2623, 206, 1683,
    ];
    if (!val) return;
    if (rank20.includes(val.countId)) {
      const rarity = val.attributes.filter(
        (val) => val.trait_type === "rarity"
      );
      console.log("Rarity: ", rarity, val);
      setRarity(rarity[0]?.value);
    } else {
      setRarity(val.rarity);
    }
  }, [val]);
  const bgColor = `${
    rarity.toLowerCase() === "legendary"
      ? "bg-[#ffd700] "
      : rarity.toLowerCase() === "epic"
      ? "bg-purple-400 "
      : rarity.toLowerCase() === "rare"
      ? " bg-blue-400"
      : rarity.toLowerCase() === "uncommon"
      ? "bg-green-400"
      : rarity.toLowerCase() === "common"
      ? " bg-gray-400"
      : ""
  }`;
  return (
    <Link
      to={`nft/${val.countId}`}
      className="text-center block  p-4  rounded-lg"
    >
      <p className="text-lg font-semibold  max-w-max mb-1  text-center mx-auto ">
        Rank: #{val.rank}
      </p>
      {/* <div className="py-0.5  mb-2 px-4 rounded-full bg-orange-400 font-semibold text-lg italic underline ">
        {val.total_rarity_score}
      </div> */}
      <div className="relative">
        <LazyLoadImage
          alt={val.countId}
          height={"270px"}
          src={getNftUrl(val.countId)}
          width={"100%"}
          placeholderSrc="./assets/loader.webp"
          className="clip"
        />
        <div
          className={`${bgColor}  font-medium text-black max-w-max px-3 py-1  rounded-lg text-xs absolute top-3  -translate-x-1/2 left-1/2`}
        >
          {rarity}
        </div>
      </div>
      <div class=" relative text-center mt-3">
        <span className="relative">#{val.countId}</span>
      </div>
    </Link>
  );
};

export default NftBox;
