import { useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-hot-toast";
import Web3 from "web3";
import { WalletModalContext } from "../../context/walletModalContext";
import { NFT_Abi, NFT_Addresses } from "../../web3/contracts/nft";
import {
  formatFromWei,
  getContract,
  mint,
  shortAddress,
} from "../../web3/helpers";
import { BiPlus, BiMinus } from "react-icons/bi";
import { FiCopy } from "react-icons/fi";
import { RPC_URLS } from "../../web3/wallet/connector";
import { ChainChangeContext } from "../../context/chainChangeContext";
import { contractAddLink } from "../../utils";
import Loader from "../../components/Loader";

const Mint = () => {
  const { connectHandler } = useContext(WalletModalContext);
  const { account, library, chainId } = useWeb3React();
  const [value, setValue] = useState(1);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(3000);
  const [displayPrice, setDisplayPrice] = useState(0);
  const [maxMint, setMaxMint] = useState(10);
  const { chain, symbol } = useContext(ChainChangeContext);
  const [loading, setLoading] = useState(false);
  // const [maxLimit, setMaxLimit] = useState(10);
  // const [saleOpen, setSalesOpen] = useState(false);
  // const [balance, setBalance] = useState(0);
  const getData = async () => {
    const provider = new Web3(new Web3.providers.HttpProvider(RPC_URLS[chain]));
    if (provider) {
      const { ok, contract } = await getContract(
        provider,
        NFT_Addresses[chain],
        NFT_Abi
      );
      if (!ok) {
        return;
      }
      try {
        let _price = await contract.methods.getPrice().call();
        // console.log("PRICE", _price);
        // setPrice(price);
        _price = formatFromWei(_price);
        setDisplayPrice(_price);
        // console.log(price);
        const MAX_SUPPlY = await contract.methods.MAX_SUPPLY().call();
        setMaxSupply(MAX_SUPPlY);
        const totalSupply = await contract.methods.totalSupply().call();
        setTotalSupply(totalSupply);
        const _maxMint = await contract.methods.MAX_ALLOWED().call();
        setMaxMint(+_maxMint);
        // const mintLimit = await contract.methods.maxMintAmount().call();
        // setMaxLimit(+mintLimit);

        // const _saleOpen = await contract.methods.saleOpen().call();
        // setSalesOpen(_saleOpen);

        // if (account) {
        //   const _balance = await contract.methods.balanceOf(account).call();
        //   setBalance(_balance);
        // }
        // const _whiteListOnly = await contract.methods.whitelistOnly().call();
        // setWhitelistOnly(_whiteListOnly);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };
  // getData();

  useEffect(() => {
    const call = async () => {
      setLoading(true);
      await getData();
      setLoading(false);
    };
    call();
    // const intervalId = setInterval(() => {
    // }, 3000);
    // return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chain, chainId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getData();
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chain, chainId]);
  const mintHandler = () => {
    if (!account) {
      connectHandler();
      return;
    }
    // return toast.error("Launching Soon!");
    mint(library, value, NFT_Addresses[chain]);
  };

  const increase = () => {
    if (value < maxMint) {
      setValue((prev) => prev + 1);
      return;
    }
    if (value === maxMint) {
      toast.error(`Max mint limit is ${maxMint}`);
    }
  };
  const decrease = () => {
    if (value > 1) {
      setValue((prev) => prev - 1);
    }
  };
  return loading ? (
    <Loader />
  ) : (
    <div className="text-white  mb-20 p-4">
      <div className=" h-full w-full max-w-[450px] mx-auto">
        <div className="bg-[#131313] mt-20 rounded-2xl p-4 py-8 text-xl h-full flex flex-col text-white ">
          <span className="text-center block mb-2">CONTRACT ADDRESS</span>
          <div className="flex items-center justify-center ">
            <>
              <a
                target="_blank"
                href={`${contractAddLink(NFT_Addresses[chain], chain)}`}
                rel="noreferrer"
              >
                {shortAddress(NFT_Addresses[chain])}
              </a>
              <CopyToClipboard
                text={NFT_Addresses[chain] ? NFT_Addresses[chain] : ""}
                onCopy={() => toast.success("Copied to clipboard")}
              >
                <button className="ml-4">
                  <FiCopy />
                </button>
              </CopyToClipboard>
            </>
          </div>

          <div className="max-w-[400px] w-full mx-auto p-4 rounded-md mt-8 pt-5 pb-10 text-center flex-1 flex flex-col justify-center items-center">
            <div className=" grid grid-flow-col gap-6 items-center mb-10">
              <button
                onClick={decrease}
                className="h-10 w-10 rounded-full bg-white text-black text-xl flex justify-center items-center"
              >
                <BiMinus />
              </button>
              <div className="text-2xl font-bold">{value}</div>
              <button
                onClick={increase}
                className="h-10 w-10 rounded-full bg-white text-black text-xl flex justify-center items-center"
              >
                <BiPlus />
              </button>
            </div>
            <p className="text-xl text-center uppercase">
              MINT {value} NFT {Number(value * displayPrice).toFixed(4)}{" "}
              {symbol}
            </p>
            <button
              className="bg-white text-black rounded-full py-2 px-10 block mx-auto mt-8 uppercase"
              onClick={mintHandler}
            >
              {account ? " MINT" : "Connect"}
            </button>
            {/* <p className="text-base mt-4 uppercase">
              TOTAL NFT MINTED: {totalSupply}/{maxSupply}
            </p> */}
            <p className="text-green-500 text-center text-sm mt-4">
              Minting is ongoing
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-[500px]  w-full mx-auto flex-1 flex justify-center flex-col items-center py-6 z-10 relative">
        <div
          className="flex justify-center
       w-full"
        >
          {/* <div className=" rounded-lg ">
          <img src="/side.png" alt="" className=" w-full  h-full rounded-2xl" />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Mint;
