import React, { useEffect, useState } from "react";
import { getNftById } from "../../service";
import { getNftUrl } from "../../utils";

const Nft = ({ id, staked }) => {
  const [loading, setLoading] = useState(false);
  const [nftData, setNftData] = useState({});
  const [rarity, setRarity] = useState("");
  useEffect(() => {
    const getData = async () => {
      try {
        const rank20 = [
          2349, 738, 567, 2692, 1296, 1347, 1063, 83, 800, 1731, 1547, 2300,
          2178, 2694, 1360, 1400, 279, 2623, 206, 1683,
        ];
        setLoading(true);
        const data = await getNftById(id);
        console.log(data);
        if (!data.ok) return;
        setNftData(data.data.nft);
        if (rank20.includes(id)) {
          let rarity = data.data.nft.attributes.filter(
            (val, i) => val.trait_type === "rarity"
          );
          setRarity(rarity[0]?.value);
        } else {
          setRarity(data.data.nft.rarity);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [id]);
  // console.log(nftData);
  // const [rarity, setRarity] = useState("");
  // console.log(val);
  // useEffect(() => {
  //   const rank20 = [
  //     2349, 738, 567, 2692, 1296, 1347, 1063, 83, 800, 1731, 1547, 2300, 2178,
  //     2694, 1360, 1400, 279, 2623, 206, 1683,
  //   ];
  //   if (!val) return;
  //   if (rank20.includes(val.countId)) {
  //     const rarity = val.attributes.filter(
  //       (val) => val.trait_type === "rarity"
  //     );
  //     console.log("Rarity: ", rarity, val);
  //     setRarity(rarity[0]?.value);
  //   } else {
  //     setRarity(val.rarity);
  //   }
  // }, [val]);
  const bgColor = `${
    rarity.toLowerCase() === "legendary"
      ? "bg-[#ffd700] "
      : rarity.toLowerCase() === "epic"
      ? "bg-purple-400 "
      : rarity.toLowerCase() === "rare"
      ? " bg-blue-400"
      : rarity.toLowerCase() === "uncommon"
      ? "bg-green-400"
      : rarity.toLowerCase() === "common"
      ? " bg-gray-400"
      : ""
  }`;
  return (
    <div>
      <div className="w-full rounded-xl  bg-[#131313] h-full">
        <div className=" relative ">
          {staked && (
            <div className="absolute top-2 right-2 bg-primary px-2 py-1 text-xs text-black rounded-full">
              Staked
            </div>
          )}{" "}
          <div
            className={`${bgColor}  font-medium text-black max-w-max px-3 py-1  rounded-lg text-xs absolute top-3  left-3`}
          >
            {rarity}
          </div>
          <img
            src={getNftUrl(id)}
            className="w-full rounded-xl min-h-[300px]"
            alt=""
          />
        </div>
        <div className=" p-4">
          <p className="text-lg font-medium">NFT Id: </p>
          <p className="text-sm mt-1">#{id}</p>{" "}
          <p className="text-lg font-medium mt-3">Rarity: </p>
          <p className="text-sm mt-1">{rarity}</p>
          <p className="text-lg font-medium mt-3">Rank: </p>
          <p className="text-sm mt-1">{nftData.rank}</p>
          <p className="text-lg font-medium mt-3">Traits: </p>
          <p className="text-sm mt-1">
            {nftData.attributes?.length > 0 &&
              nftData.attributes.map((attr, index) => (
                <p key={index} className=" uppercase">
                  <span>{attr.trait_type}</span> : {attr.value}
                </p>
              ))}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Nft;
