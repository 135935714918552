import { toast } from "react-hot-toast";
import Web3 from "web3";
import { CHAINLIST } from "../../utils";
import { NFT_Abi } from "../contracts/nft";
import { tokenAbi } from "../contracts/token";
import { transferAbi } from "../contracts/transferContract";

export const shortAddress = (str) => {
  if (str) {
    if (str.length < 10) return str;
    return `${str.slice(0, 5)}...${str.slice(-5)}`;
  } else {
    return "";
  }
};
export const toHex = (num) => {
  const val = Number(num);
  return `0x${val.toString(16)}`;
};

export const formatFromWei = (str, decimal) => {
  str = `${str}`;
  if (str) {
    if (str.length < 1) return str;
    if (decimal === 9) {
      return Number(Web3.utils.fromWei(str, "Gwei")).toFixed(2);
    }
    return Number(Web3.utils.fromWei(str, "ether")).toFixed(4);
  }
};
export const formatToWei = (str, decimal) => {
  if (str) {
    if (str.length < 1) return str;
    if (decimal === 9) {
      return Web3.utils.toWei(str, "Gwei");
    }
    return Web3.utils.toWei(str, "ether");
  }
};

export const formatFromWei2 = (str, decimal = 18) => {
  decimal = Number(decimal);
  str = `${str}`;
  if (!str || !decimal) {
    return Number(0).toFixed(2);
  }
  if (decimal === 9) {
    return Number(Web3.utils.fromWei(str, "Gwei")).toFixed(2);
  }
  if (decimal === 18) {
    return Number(Web3.utils.fromWei(str, "ether")).toFixed(2);
  }
  return Number(+str / 10 ** decimal).toFixed(2);
};
export const formatToWei2 = (str, decimal = 18) => {
  decimal = Number(decimal);
  str = `${str}`;
  if (str) {
    if (decimal === 9) {
      return Web3.utils.toWei(str, "Gwei");
    }
    if (decimal === 18) {
      return Web3.utils.toWei(str, "ether");
    }
    return (+str * 10 ** decimal).toString();
  }
};
export const getContract = async (library, token, abi) => {
  try {
    let _contract = null;
    if (token && !abi) {
      _contract = await new library.eth.Contract(tokenAbi, token);
    } else if (token && abi) {
      _contract = await new library.eth.Contract(abi, token);
    }
    return { ok: true, contract: _contract };
  } catch (error) {
    console.log(error);
    return { ok: false, contract: null };
  }
};

export const getUserBalance = async (library, account) => {
  try {
    let balance = await library.eth.getBalance(account);
    balance = formatFromWei(balance);

    return Number(balance).toFixed(4);
  } catch (error) {
    console.log(error);
    return 0;
  }
};
export const getTokenDetails = async (library, tokenAddress) => {
  const { ok, contract: tokenContract } = await getContract(
    library,
    tokenAddress,
    tokenAbi
  );
  if (!ok) return null;
  try {
    const decimals = await tokenContract.methods.decimals().call();
    const symbol = await tokenContract.methods.symbol().call();
    return { decimals, symbol };
  } catch (error) {
    return null;
  }
};

export const getTokenBalance = async (library, tokenAddress, account) => {
  const { ok, contract: tokenContract } = await getContract(
    library,
    tokenAddress,
    tokenAbi
  );
  if (!ok) return 0;
  try {
    const decimals = await tokenContract.methods.decimals().call();
    let balance = await tokenContract.methods.balanceOf(account).call();
    balance = formatFromWei2(balance, decimals);
    return balance;
  } catch (error) {
    return 0;
  }
};

export const claim = async (library, stakeId, contractAddress, abi) => {
  const { ok, contract } = await getContract(library, contractAddress, abi);

  if (!ok) {
    toast.error("Something went wrong!");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  toast.loading("Please Confirm Transaction..", { id: toastId });
  try {
    const isClaimable = await contract.methods.isClaimable().call();
    if (!isClaimable) {
      return toast.error("Insufficient contract balance to withdraw", {
        id: toastId,
      });
    }
    contract.methods
      .claimRewards(stakeId)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.success("Transaction Completed Successfully", { id: toastId });

        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    console.log("ERROR", error);
    toast.error("Something went wrong!", { id: toastId });
  }
};

export const unStake = async (library, stakeId, contractAddress, abi) => {
  const { ok, contract } = await getContract(library, contractAddress, abi);

  if (!ok) {
    toast.error("Something went wrong!");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  toast.loading("Please Confirm Transaction..", { id: toastId });
  // console.log(stakeId);
  try {
    const isClaimable = await contract.methods.isClaimable().call();
    if (!isClaimable) {
      return toast.error("Insufficient contract balance to withdraw", {
        id: toastId,
      });
    }
    contract.methods
      .unstake(stakeId)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.success("Transaction Completed Successfully", { id: toastId });

        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    console.log("ERROR", error);
    toast.error("Something went wrong!", { id: toastId });
  }
};

export const reStake = async (library, stakeId) => {
  const { ok, contract } = await getContract(library);

  if (!ok) {
    toast.error("Something went wrong!");
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  toast.loading("Please Confirm Transaction..", { id: toastId });

  contract.methods
    .restake(stakeId)
    .send({
      from: accounts[0],
    })
    .on("transactionHash", function () {
      toast.loading(
        "Please wait to get confirmation of the transaction from blockchain",
        { id: toastId }
      );
    })
    .on("confirmation", function (receipt) {
      console.log("CONFIRMATION", receipt);
    })
    .on("receipt", async function (receipt) {
      toast.success("Transaction Completed Successfully", { id: toastId });

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    })
    .on("error", function (error, receipt) {
      if (error.code === 4001) {
        toast.error("Transaction Rejected", { id: toastId });
      } else {
        toast.error("Transaction Failed", { id: toastId });
      }
    });
};

export const approveToken = async (
  library,
  input,
  tokenAddress,
  contractAddress,
  successHandler,
  setLoading = () => {}
) => {
  const { ok, contract: tokenContract } = await getContract(
    library,
    tokenAddress,
    tokenAbi
  );

  if (!ok) {
    setLoading(false);
    return toast.error("Something went wrong");
  }
  setLoading(true);
  const accounts = await library.eth.getAccounts();

  const toastId = toast.loading("Please Wait Transaction is processing");
  const decimals = await tokenContract.methods.decimals().call();
  let balance = await tokenContract.methods.balanceOf(accounts[0]).call();
  balance = formatFromWei2(balance.toString(), decimals);
  console.log("BALANCE", balance);

  if (+balance < input || +balance === 0) {
    setLoading(false);
    toast.error("You dont have sufficient balance.", { id: toastId });
    return;
  }
  const allowance = await tokenContract.methods
    .allowance(accounts[0], contractAddress)
    .call();
  const totalSupply = await tokenContract.methods.totalSupply().call();
  console.log(allowance, totalSupply);

  toast.loading("Please Allow our protocal to use your token", { id: toastId });
  try {
    tokenContract.methods
      .approve(contractAddress, totalSupply)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        // console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.success("Approve Transaction successfully completed!", {
          id: toastId,
        });
        setLoading(false);
        successHandler();
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
        setLoading(false);
      });
  } catch (error) {
    toast.error("Something went wrong!", { id: toastId });
    setLoading(false);
  }
};

export const approveNft = async (
  library,
  nftId,
  contractAddress,
  NFT_Address,
  successHandler
) => {
  const { ok, contract: nftContract } = await getContract(
    library,
    NFT_Address,
    NFT_Abi
  );
  console.log(nftId, contractAddress);
  if (!ok) {
    return toast.error("Something went wrong");
  }
  console.log(nftContract);
  const accounts = await library.eth.getAccounts();

  const toastId = toast.loading("Please Wait Transaction is processing");

  toast.loading("Please Allow our protocal to use your Nft", { id: toastId });
  try {
    nftContract.methods
      .approve(contractAddress, nftId)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        // console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.success("Approve transaction successfully completed", {
          id: toastId,
        });
        successHandler();
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    console.log("ERROR", error);
    toast.error("Something went wrong!", { id: toastId });
  }
};
export const approveForAllNft = async (
  library,
  contractAddress,
  successHandler,
  NFT_Address
) => {
  const { ok, contract: nftContract } = await getContract(
    library,
    NFT_Address,
    NFT_Abi
  );
  // console.log(nftId, contractAddress);
  if (!ok) {
    return toast.error("Something went wrong");
  }
  console.log(nftContract);
  const accounts = await library.eth.getAccounts();

  const toastId = toast.loading("Please Wait Transaction is processing");

  toast.loading("Please Allow our protocal to use your Nft", { id: toastId });
  try {
    nftContract.methods
      .setApprovalForAll(contractAddress, true)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        // console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.success("Approve transaction successfully completed", {
          id: toastId,
        });
        successHandler();
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    console.log("ERROR", error);
    toast.error("Something went wrong!", { id: toastId });
  }
};
export const stake = async (
  library,
  poolId,
  nftId,
  nftIds,
  amount,
  contractAddress,
  contractAbi,
  tokenAddress,
  chainId,
  successHandler,
  setLoading = () => {}
) => {
  const { ok, contract } = await getContract(
    library,
    contractAddress,
    contractAbi
  );
  const { ok: tokenOk, contract: tokenContract } = await getContract(
    library,
    tokenAddress,
    tokenAbi
  );
  // console.log(contract, ok);
  if (!ok || !tokenOk) {
    return toast.error("Something went wrong");
  }
  if (!amount) {
    return toast.error("Amount is required");
  }
  setLoading(true);
  const accounts = await library.eth.getAccounts();

  const toastId = toast.loading("Please Wait Transaction is processing");

  // toast.loading("Please Allow our protocal to use your Nft", { id: toastId });
  const decimals = await tokenContract.methods.decimals().call();
  let finalAmount = formatToWei2(amount, decimals);
  console.log(nftIds);
  const tokenIds = nftIds.map((nft) => nft.id);
  // const params =
  //   chainId === CHAINLIST.BSCT ? { poolId, nftId, finalAmount } : null;
  // console.log(poolId, nftId, finalAmount);
  // console.log(poolId, tokenIds, finalAmount);
  if (chainId === CHAINLIST.BSCT || chainId === CHAINLIST.BSC) {
    try {
      contract.methods
        .stake(poolId, nftId, finalAmount)
        .send({
          from: accounts[0],
        })
        .on("transactionHash", function () {
          toast.loading(
            "Please wait to get confirmation of the transaction from blockchain",
            { id: toastId }
          );
        })
        .on("confirmation", function (receipt) {
          console.log("CONFIRMATION", receipt);
        })
        .on("receipt", async function (receipt) {
          toast.success(" Transaction successfully completed", {
            id: toastId,
          });
          setLoading(false);
          successHandler();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .on("error", function (error, receipt) {
          if (error.code === 4001) {
            toast.error("Transaction Rejected", { id: toastId });
          } else {
            toast.error("Transaction Failed", { id: toastId });
          }
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", { id: toastId });
      setLoading(false);
    }
  } else {
    try {
      contract.methods
        .stake(poolId, tokenIds, finalAmount)
        .send({
          from: accounts[0],
        })
        .on("transactionHash", function () {
          toast.loading(
            "Please wait to get confirmation of the transaction from blockchain",
            { id: toastId }
          );
        })
        .on("confirmation", function (receipt) {
          console.log("CONFIRMATION", receipt);
        })
        .on("receipt", async function (receipt) {
          toast.success(" Transaction successfully completed", {
            id: toastId,
          });
          setLoading(false);
          successHandler();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .on("error", function (error, receipt) {
          if (error.code === 4001) {
            toast.error("Transaction Rejected", { id: toastId });
          } else {
            toast.error("Transaction Failed", { id: toastId });
          }
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", { id: toastId });
      setLoading(false);
    }
  }
};
export const approveAndInject = async (
  library,
  input,
  contractAddress,
  tokenAddress,
  contractAbi
) => {
  if (!contractAddress || !tokenAddress) {
    return toast.error("contractAddress and tokenAddress are required");
  }
  const { ok, contract: tokenContract } = await getContract(
    library,
    tokenAddress
  );
  const { ok: contractOK, contract } = await getContract(
    library,
    contractAddress,
    contractAbi
  );
  const accounts = await library.eth.getAccounts();

  if (!ok || !contractOK) {
    toast.error("Something went wrong, please try again later");
    return;
  }

  if (!input) {
    toast.error(`Invalid Input`);
    return;
  }

  const toastId = toast.loading("Please Wait Transaction is processing");
  const decimals = await tokenContract.methods.decimals().call();
  let balance = await tokenContract.methods.balanceOf(accounts[0]).call();
  balance = formatFromWei2(balance.toString(), decimals);
  // console.log("BALANCE", balance);

  if (+balance < input || +balance === 0) {
    toast.error("You dont have sufficient balance.", { id: toastId });
    return;
  }
  const allowance = await tokenContract.methods
    .allowance(accounts[0], contractAddress)
    .call();
  const totalSupply = await tokenContract.methods.totalSupply().call();
  console.log(allowance, totalSupply);
  const value = formatToWei2(input, decimals);
  // return;
  if (+allowance > 0) {
    try {
      contract.methods
        .injectRewardsSupply(value)
        .send({
          from: accounts[0],
        })
        .on("transactionHash", function () {
          toast.loading(
            "Please wait to get confirmation of the transaction from blockchain",
            { id: toastId }
          );
        })
        .on("confirmation", function (receipt) {
          console.log("CONFIRMATION", receipt);
        })
        .on("receipt", async function (receipt) {
          toast.success("Transaction Completed Successfully", { id: toastId });

          setTimeout(() => {
            // window.location.href = "/";
            window.location.reload(false);
          }, 1000);
        })
        .on("error", function (error, receipt) {
          if (error.code === 4001) {
            toast.error("Transaction Rejected", { id: toastId });
          } else {
            toast.error("Transaction Failed", { id: toastId });
          }
        });
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!", { id: toastId });
    }
    return;
  }

  toast.loading("Please Allow our protocal to use your token", { id: toastId });
  try {
    tokenContract.methods
      .approve(contractAddress, totalSupply)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.loading("Please confirm transaction to deposit", { id: toastId });

        contract.methods
          .injectRewardsSupply(value)
          .send({
            from: accounts[0],
          })
          .on("transactionHash", function () {
            toast.loading(
              "Please wait to get confirmation of the transaction from blockchain",
              { id: toastId }
            );
          })
          .on("confirmation", function (receipt) {
            console.log("CONFIRMATION", receipt);
          })
          .on("receipt", async function (receipt) {
            toast.success("Transaction Completed Successfully", {
              id: toastId,
            });

            setTimeout(() => {
              window.location.href = "/rewards";
              // window.location.reload(false);
            }, 1000);
          })
          .on("error", function (error, receipt) {
            if (error.code === 4001) {
              toast.error("Transaction Rejected", { id: toastId });
            } else {
              toast.error("Transaction Failed", { id: toastId });
            }
          });
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    toast.error("Something went wrong!", { id: toastId });
  }
};

export const mint = async (library, input, NFT_Address) => {
  const { ok, contract } = await getContract(library, NFT_Address, NFT_Abi);

  if (!ok) {
    toast.error(
      "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"
    );
    return;
  }
  if (!input || Number(input) <= 0) {
    toast.error("Invalid Input!");
    return;
  }
  const toastId = toast.loading("Please Wait Transaction is processing");
  const accounts = await library.eth.getAccounts();

  const price = await contract.methods.getPrice().call();
  const finalPrice = Number(price) * input;
  const isSaleOpen = await contract.methods.saleOpen().call();
  // const mintLimit = await contract.methods.maxMintAmount().call();
  const balance = await contract.methods.balanceOf(accounts[0]).call();

  const userBalance = await getUserBalance(library, accounts[0]);
  console.log(userBalance, finalPrice);
  const formatedFinalPrice = formatFromWei(finalPrice);

  console.log(formatedFinalPrice, userBalance);
  if (+userBalance <= +formatedFinalPrice) {
    toast.error("You dont have sufficient balance", { id: toastId });
    return;
  }

  // if (balance >= 1) {
  //   toast.error("Max 1 mint allowed per wallet", { id: toastId });
  //   return;
  // }

  if (!isSaleOpen) {
    toast.error(
      "Sale is not Open Yet, Please follow our twitter and discord for updates",
      { id: toastId }
    );
    return;
  }
  try {
    contract.methods
      .mintNFT(input)
      .send({
        from: accounts[0],
        value: finalPrice,
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.success("NFTs minted successfully", { id: toastId });

        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    console.log(error);
  }
};
export const transferNFTs = async (
  library,
  nftIds,
  transferAddress,
  nftAddress
) => {
  const { ok, contract } = await getContract(
    library,
    transferAddress,
    transferAbi
  );

  if (!ok) {
    return toast.error("Something went wrong");
  }
  console.log(nftIds);
  const tokenIds = nftIds.map((nft) => nft.id);
  // console.log(tokenIds);
  // // return;
  // console.log(contract);
  const accounts = await library.eth.getAccounts();

  const toastId = toast.loading("Please Wait Transaction is processing");

  toast.loading("Please Allow our protocal to use your Nft", { id: toastId });
  try {
    contract.methods
      .transferMultipleNfts(nftAddress, tokenIds)
      .send({
        from: accounts[0],
      })
      .on("transactionHash", function () {
        toast.loading(
          "Please wait to get confirmation of the transaction from blockchain",
          { id: toastId }
        );
      })
      .on("confirmation", function (receipt) {
        // console.log("CONFIRMATION", receipt);
      })
      .on("receipt", async function (receipt) {
        toast.success("Approve transaction successfully completed", {
          id: toastId,
        });
        // successHandler();
      })
      .on("error", function (error, receipt) {
        if (error.code === 4001) {
          toast.error("Transaction Rejected", { id: toastId });
        } else {
          toast.error("Transaction Failed", { id: toastId });
        }
      });
  } catch (error) {
    console.log("ERROR", error);
    toast.error("Something went wrong!", { id: toastId });
  }
};
