import React, { useContext, useEffect } from "react";
import { Collapse } from "@mui/material";
import { useState } from "react";
import Button from "../../../components/Button";
import Pool from "./Pool";
import { useWeb3React } from "@web3-react/core";
import {
  formatFromWei2,
  getContract,
  getTokenDetails,
} from "../../../web3/helpers";
import { tokenAbi } from "../../../web3/contracts/token";
import Web3 from "web3";
import {
  RPC_URL,
  staticApys,
  contractAddLink,
  tokenData,
} from "../../../utils";
import { RPC_URLS } from "../../../web3/wallet/connector";
import { ChainChangeContext } from "../../../context/chainChangeContext";

const Token = ({ address, abi, type, index, title }) => {
  const [open, setOpen] = useState(false);
  const { account, library, chainId } = useWeb3React();
  const [pools, setPools] = useState([]);
  const { chain } = useContext(ChainChangeContext);
  const [baseTokenAddress, setBaseTokenAddress] = useState("");
  // const [tokenSymbol, setTokenSymbol] = useState("");
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [stakes, setStakes] = useState([]);
  const [rewardTokenAddress, setRewardTokenAddress] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [usersTotalStaked, setUsersTotalStaked] = useState(0);
  const [usersTotalClaimed, setUsersTotalClaimed] = useState(0);
  // const [decimals, setDecimals] = useState(0);
  const [tokenDetails, setTokenDetails] = useState({});
  const [rewardTokenDetails, setRewardTokenDetails] = useState({});
  const [sameToken, setSameToken] = useState(false);
  useEffect(() => {
    const provider = new Web3(new Web3.providers.HttpProvider(RPC_URLS[chain]));
    const getStakeData = async () => {
      if (provider) {
        const { ok, contract } = await getContract(provider, address, abi);

        if (!ok) {
          return;
        }
        try {
          const baseTokenAddress = await contract.methods
            .baseTokenAddress()
            .call();
          setBaseTokenAddress(baseTokenAddress);
          const rewardTokenAddress = await contract.methods
            .rewardTokensAddress()
            .call();
          setRewardTokenAddress(rewardTokenAddress);

          const rewardTokenDetails = await getTokenDetails(
            provider,
            rewardTokenAddress
          );
          setRewardTokenDetails(rewardTokenDetails);
          const tokenDetails = await getTokenDetails(
            provider,
            baseTokenAddress
          );
          setTokenDetails(tokenDetails);
          const allPools = await contract.methods.getAllPoolDetails().call();
          setPools(allPools);
          const amountStaked = await contract.methods
            .totalStakedBalance()
            .call();
          const convertedStakedValue = formatFromWei2(
            amountStaked,
            tokenDetails.decimals
          );
          setTotalStaked(convertedStakedValue);
          const amountClaimed = await contract.methods
            .totalClaimedBalance()
            .call();
          const convertedAmountClamied = formatFromWei2(
            amountClaimed,
            rewardTokenDetails.decimals
          );
          setTotalClaimed(convertedAmountClamied);
          setSameToken(
            rewardTokenAddress.toLowerCase() === baseTokenAddress.toLowerCase()
          );
        } catch (error) {
          console.log(error);
        }
      }
    };
    getStakeData();
  }, [address, abi, chain]);

  // useEffect(() => {
  //   const getTokenData = async () => {
  //     const provider = new Web3(new Web3.providers.HttpProvider(RPC_URL));
  //     if (provider) {
  //       if (!baseTokenAddress) return;
  //       const { ok, contract } = await getContract(
  //         provider,
  //         baseTokenAddress,
  //         tokenAbi
  //       );

  //       if (!ok || !baseTokenAddress) {
  //         return;
  //       }
  //       try {
  //         const symbol = await contract.methods.symbol().call();
  //         setTokenSymbol(symbol);
  //         const decimals = await contract.methods.decimals().call();
  //         setDecimals(decimals);
  //         console.log(symbol, decimals);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   };
  //   getTokenData();
  // }, [baseTokenAddress]);
  useEffect(() => {
    const getTokenData = async () => {
      if (account) {
        console.log(address);
        const { ok, contract } = await getContract(library, address, abi);

        if (!ok) {
          return;
        }
        try {
          const userStakes = await contract.methods
            .getUserAllStakeDetailsByAddress(account)
            .call();

          setStakes(userStakes);

          const users = await contract.methods.users(account).call();
          setUsersTotalStaked(
            formatFromWei2(users.totalStakedBalance, tokenDetails.decimals)
          );
          setUsersTotalClaimed(
            formatFromWei2(
              users.totalClaimedRewards,
              rewardTokenDetails.decimals
            )
          );
          const owner = await contract.methods.owner().call();

          setIsAuthorized(owner.toLowerCase() === account.toLowerCase());
        } catch (error) {
          console.log(error);
        }
      }
    };
    getTokenData();
  }, [library, account, address, abi, tokenDetails, rewardTokenDetails]);
  const dataObj = tokenData[chain]?.[type]?.[index];
  return (
    <div className=" bg-dark-500 p-4 rounded-lg">
      <div className="grid gap-2 text-center md:text-left md:flex md:justify-between md:items-center ">
        <div>
          <div className="flex items-center justify-center md:justify-start">
            <div className="relative mr-4">
              <img
                src={dataObj?.baseLogo}
                alt=""
                className="w-8 h-8 rounded-full relative z-10"
              />{" "}
              {!sameToken && (
                <img
                  src={dataObj?.rewardLogo}
                  alt=""
                  className="w-8 h-8 rounded-full absolute top-1/2 -translate-y-1/2 translate-x-1/2"
                />
              )}
            </div>
            <div className="ml-2">
              <p className="text-left">
                {/* {title ? title : `Stake ${tokenSymbol}`} */}
                {`Stake ${tokenDetails.symbol} / Earn ${rewardTokenDetails.symbol}`}
              </p>
              <div className="grid grid-flow-col  gap-1 justify-start text-[10px]">
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={`${dataObj?.websiteLink}`}
                  className="text-primary"
                >
                  Website
                </a>{" "}
                |{" "}
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={`${dataObj?.telegramLink}`}
                  className="text-primary"
                >
                  Telegram
                </a>{" "}
                |
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={`${dataObj?.twitterLink}`}
                  className="text-primary"
                >
                  Twitter
                </a>
              </div>
            </div>
          </div>
          {isAuthorized && (
            <a
              href={contractAddLink(address, chainId)}
              target="_blank"
              rel="noreferrer"
              className="text-primary block mt-1 text-xs"
            >
              View Contract
            </a>
          )}
        </div>
        <p className="">Total Staked: {totalStaked}</p>
        <p>Total Claimed: {totalClaimed}</p>
        <div className="grid grid-flow-col gap-2 ">
          {isAuthorized && rewardTokenAddress && (
            <Button
              sm
              link
              to={`inject_reward/${address}/${rewardTokenAddress}`}
            >
              Inject
            </Button>
          )}
          <Button sm onClick={() => setOpen(!open)}>
            View pools
          </Button>
        </div>
      </div>

      <Collapse in={open}>
        <div className="grid md:grid-flow-col gap-10 justify-center items-center my-7 text-center">
          <div className="">
            <p className=" font-bold text-xl">
              {" "}
              Your {tokenDetails.symbol} staked
            </p>
            <p>{usersTotalStaked}</p>
          </div>{" "}
          <div className="">
            <p className=" font-bold text-xl">
              {" "}
              Your {rewardTokenDetails.symbol} earned
            </p>
            <p>{usersTotalClaimed}</p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 mt-7">
          {pools.length > 0 ? (
            pools.map((pool, index) => (
              <React.Fragment key={index}>
                <Pool
                  data={pool}
                  address={address}
                  abi={abi}
                  userStakes={stakes}
                  apy={staticApys[index]}
                />
              </React.Fragment>
            ))
          ) : (
            <p className=" font-semibold py-6 text-center">
              No staking pools available yet
            </p>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default Token;
