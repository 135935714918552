import { Collapse } from "@mui/material";
import React, { useContext, useState } from "react";
import Button from "../../../components/Button";
import { ChainChangeContext } from "../../../context/chainChangeContext";
import { contractAbis } from "../../../web3/contracts/primary";
import Token from "./Token";

const StakeShibGetOther = ({ stakes }) => {
  const [open, setOpen] = useState(false);
  const { chain } = useContext(ChainChangeContext);
  return (
    <div className=" bg-dark rounded-xl p-6 ">
      <div className="md:flex justify-between items-center">
        <div className="mb-3 md:mb-0">
          <p className=" text-xl md:text-2xl font-bold">
            Stake SHIBELON to earn rewards in partnered tokens currency
          </p>
          <p className="md:text-base text-sm">
            Hold a SHIBELON NFT & stake SHIBELON tokens to start earning rewards
          </p>
        </div>
        <Button sm onClick={() => setOpen(!open)}>
          View Stakes
        </Button>
      </div>

      <Collapse in={open}>
        {/* <p className="text-center py-10">
          No pools available at this time, check back later
        </p> */}
        <div className="py-5 grid grid-cols-1 gap-4">
          {stakes.length > 0 ? (
            stakes.map((token, index) => (
              <React.Fragment key={index}>
                <Token
                  address={token}
                  abi={contractAbis[chain]}
                  type={2}
                  index={index}
                />
              </React.Fragment>
            ))
          ) : (
            <p className=" font-semibold py-6 text-center">
              No staking pools available yet
            </p>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default StakeShibGetOther;
