// import { useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect, useState } from "react";
import { getContract } from "../../web3/helpers";
import StakeShibGetShib from "./components/StakeShibGetShib";
import StakeAnyGetSame from "./components/StakeAnyGetSame";
import StakeShibGetOther from "./components/StakeShibGetOther";
import {
  masterAbi,
  masterAbis,
  masterAddresses,
} from "../../web3/contracts/master";
import Web3 from "web3";
import { RPC_URLS } from "../../web3/wallet/connector";
import { ChainChangeContext } from "../../context/chainChangeContext";
import { useWeb3React } from "@web3-react/core";
import Loader from "../../components/Loader";
import Marquee from "react-fast-marquee";
import { dexLinks } from "../../utils";
import { FaFire } from "react-icons/fa";

const Home = () => {
  const { chainId } = useWeb3React();
  const { currentChain } = useContext(ChainChangeContext);
  const [stakeShibGetShibContracts, setStakeShibGetShibContracts] = useState(
    []
  );
  const [stakeAnyGetAnyContracts, setStakeAnyGetAnyContracts] = useState([]);
  const [stakeShibGetAnyContracts, setStakeShibGetAnyContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const getStakeData = async () => {
    const provider = new Web3(
      new Web3.providers.HttpProvider(RPC_URLS[currentChain.chain])
    );
    if (provider) {
      const { ok, contract } = await getContract(
        provider,
        masterAddresses[currentChain.chain],
        masterAbis[currentChain.chain]
      );
      if (!ok) {
        return;
      }
      try {
        const _allStakes = await contract.methods.getAllStakes().call();
        setStakeShibGetShibContracts(_allStakes[0]);
        setStakeShibGetAnyContracts(_allStakes[1]);
        setStakeAnyGetAnyContracts(_allStakes[2]);
        // console.log(_allStakes);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getStakeData();
      setLoading(false);
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentChain, chainId]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getStakeData();
  //   }, 10000);
  //   return () => clearInterval(intervalId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentChain, chainId]);
  return loading ? (
    <Loader />
  ) : (
    <div className="mt-6 md:mt-0 mb-20">
      <div className="grid grid-cols-1 lg:grid-cols-12  gap-8 text-white">
        {/* <div className=" lg:col-span-8  bg-dark rounded-xl p-6 ">
          <Stakes />
        </div> */}
        <div className="lg:col-span-12  px-5 relative">
          <div className=" relative pl-10  bg-[#131313] rounded-full">
            <div className="absolute left-0 flex justify-center items-center px-3 h-full bg-[#131313] z-10 rounded-l-full">
              <FaFire /> <span className="ml-1 md:block hidden">Trending</span>
            </div>
            <Marquee
              className=" bg-gray w-full mb-10 py-2 rounded-full"
              gradient={false}
              pauseOnHover
              speed={100}
            >
              {dexLinks.map((val, index) => (
                <a
                  href={val.link[currentChain.chain]}
                  key={index}
                  target="_blank"
                  className=" grid grid-flow-col gap-1 ml-2 text-white bg-[#000] p-2 rounded-full items-center px-4"
                  rel="noreferrer"
                >
                  <span className="text-gray-400">#{index + 1}</span>{" "}
                  {val.symbol}
                  <img
                    src={val.logo}
                    className="w-6 h-6 rounded-full "
                    alt=""
                  />{" "}
                </a>
              ))}
            </Marquee>
          </div>
          <div className="grid grid-cols-1 gap-10">
            <StakeShibGetShib stakes={stakeShibGetShibContracts} />
            <StakeShibGetOther stakes={stakeShibGetAnyContracts} />
            <StakeAnyGetSame stakes={stakeAnyGetAnyContracts} />
            {/*
             */}
            {/*
             */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
