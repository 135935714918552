import { Backdrop } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { getTraits } from "../../../service";
import RaritySelect from "./RaritySelect";

const FilterModal = ({ open, setOpen, setFilterTraits }) => {
  const [traits, setTraits] = useState([]);
  const [selectedTraits, setSelectedTraits] = useState({});
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getTraits();
        if (!data.ok) return;

        console.log(data.data.traits);
        setTraits(data.data.traits);
        let values = {};
        for (const traitValue of data.data.traits) {
          values[traitValue.trait_type] = "";
        }
        setSelectedTraits(values);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);
  // console.log(selectedTraits);
  useEffect(() => {
    let str = Object.values(selectedTraits);
    str = str.filter((val) => val !== "");
    if (str.length > 0) {
      setSelected(true);
      let str = Object.values(selectedTraits);
      str = str.filter((val) => val !== "");
      // console.log(str);
      setFilterTraits(str);
    } else {
      setSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTraits]);

  const clearAll = () => {
    let values = {};
    for (const traitValue of traits) {
      values[traitValue.trait_type] = "";
    }
    setSelectedTraits(values);
    setFilterTraits("");
  };
  const closeHandle = () => {
    let str = Object.values(selectedTraits);
    str = str.filter((val) => val !== "");
    // console.log(str);
    setFilterTraits(str);
    setOpen(false);
  };
  return (
    <>
      {/* <Backdrop open={open} onClick={closeHandle}></Backdrop> */}
      <div
        className={` transition-all duration-200   z-50  h-full 
        text-white p-5 overflow-y-auto`}
      >
        <div className=" flex justify-between ">
          {selected && (
            <button
              className="block ml-auto text-primary"
              // className="text-primary"
              onClick={clearAll}
            >
              Clear All
            </button>
          )}
          {/* <button onClick={closeHandle} className="block ml-auto">
            <FaTimes className="text-xl" />
          </button> */}
        </div>
        <div className="  grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {traits.map((item, index) => (
            <div key={index}>
              <RaritySelect
                data={item}
                selectedTraits={selectedTraits}
                setSelectedTraits={setSelectedTraits}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FilterModal;
