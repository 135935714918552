// import { useWeb3React } from "@web3-react/core";
import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import NothingToShow from "../../components/NothingToShow";
import SearchBox from "../../components/SearchBox";
import { getNfts } from "../../service";
import FilterModal from "./components/FilterModal";
import Nfts from "./components/Nfts";

const Rarity = () => {
  // const { account, library } = useWeb3React();
  const [filterOpen, setFilterOpen] = useState(false);
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalNfts, setTotalNfts] = useState(0);
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [filterTraits, setFilterTraits] = useState([]);
  const [searchText, setSearchText] = useState("");
  const getData = async () => {
    try {
      setLoading(true);
      const data = await getNfts(page, sort, filterTraits, searchText);
      console.log(data);
      setNfts(data.data.nfts);
      setTotalNfts(data.data.totalNfts);
      setPages(data.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, filterTraits]);

  // console.log(nfts);
  const handlePage = (event, value) => {
    setPage(value);
  };
  return (
    <div className="container">
      <div className=" mt-10 mb-20 text-white">
        <h1 className="text-5xl font-bold   text-center mt-20 block text-primary mb-7">
          SHIBELON NFTS
        </h1>
        <SearchBox
          value={searchText}
          setValue={setSearchText}
          submitHandler={getData}
        />
        <div className="grid md:grid-flow-col gap-2 justify-center items-center mt-7 mb-6">
          <button
            onClick={() => setFilterOpen(true)}
            className="bg-primary px-10 w-full rounded-full py-2 text-black"
          >
            Trait Filter ({filterTraits.length})
          </button>
          <select
            className="text-black rounded-full"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="rank">Sort by Rarity</option>
            <option value="countId">Sort by Id</option>
          </select>
        </div>
        <FilterModal
          open={filterOpen}
          setOpen={setFilterOpen}
          setFilterTraits={setFilterTraits}
        />
        <div className=" w-full mx-auto mt-20">
          {/* <p className="text-2xl">Total: {totalNfts}</p> */}
          <div className="mt-10">
            {!loading ? (
              nfts.length > 0 ? (
                <Nfts nfts={nfts} />
              ) : (
                <NothingToShow />
              )
            ) : (
              <Loader />
            )}
          </div>
          {nfts.length > 0 && (
            <Pagination
              count={pages}
              page={page}
              defaultPage={1}
              onChange={handlePage}
              shape="rounded"
              variant="outlined"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Rarity;
