import { useWeb3React } from "@web3-react/core";
import React, { useContext } from "react";
import Button from "../../components/Button";
import { WalletModalContext } from "../../context/walletModalContext";
import NftBox from "./NftBox";

const MyNfts = () => {
  const { account } = useWeb3React();
  const { connectHandler } = useContext(WalletModalContext);
  // eslint-disable-next-line no-unused-vars
  // const lists = [
  //   {
  //     title: "Hat/Hair = 10 items",
  //     items: [
  //       "No Hat",
  //       "Elon Musk Hair",
  //       "Ice cap",
  //       "Devil horns",
  //       "Spiky white hair",
  //       "Yu-gi-oh Hair",
  //       "Sideways cap that says KING",
  //       "Super Saiyan",
  //       "Hair with banadana like street fighter",
  //       "Blue spiky hair (Same hex color as the blue in the website)",
  //     ],
  //   },
  //   {
  //     title: "Eyes = 7 items",
  //     items: [
  //       "Normal eyes",
  //       "Full Red color eyes",
  //       "Laser eyes",
  //       "Full white eyes like a ghost",
  //       "Blue eyes (Same hex color as the blue in the website)",
  //       "Infinity stone on the head like in vision from avengers",
  //       "3 Eyes on in the middle top like the chakra",
  //     ],
  //   },
  //   {
  //     title: "Mouth - 6 items",
  //     items: [
  //       "Angry",
  //       "Laughing big mouth",
  //       "Normal",
  //       "Golden teeth",
  //       "Diamond teeth",
  //       "Smirk",
  //     ],
  //   },
  //   {
  //     title: "Outfit - 7 items",
  //     items: [
  //       "White Fur",
  //       "Suite like Elon Musk with Tesla Logo",
  //       "Black skin suit muscular look",
  //       "Motorcycle jacket (one in black, one in white)",
  //       "Hoody (Shibelon blue color, Red shiba color, Orange Doge color",
  //       "Lakers Basketball",
  //       "T-shirt with SHIBELON logo on it (colors: green, grey, dark blue, purple, white)",
  //     ],
  //   },
  //   {
  //     title: "Background - 10 items",
  //     items: [
  //       "Dark Grey",
  //       "White",
  //       "SHIBELON blue",
  //       "Red",
  //       "Highlighter green",
  //       "Yellow",
  //       "Neptune planet background",
  //       "On the moon background",
  //       " in Tesla office background",
  //       "in Hell background",
  //     ],
  //   },
  //   {
  //     title: "Unique - 19 items",
  //     items: [
  //       "Ultron from Marvel",
  //       "Bored Ape Yacht Club",
  //       "Thor from marvel",
  //       "Joker",
  //       "Superman",
  //       "Soulja boy",
  //       "Batman",
  //       "Pickachu",
  //       "Mewtwo",
  //       "Deadpool",
  //       "Naruto",
  //       "Venom",
  //       "DogeElon",
  //       "Elon Musk",
  //       "Spiderman",
  //       "Lee sin from league of legends",
  //       "Darius from league of legends",
  //       " Yasuo from league of legends",
  //       "Avatar",
  //     ],
  //   },
  // ];
  return (
    <div className="text-white p-4 w-full">
      {account ? (
        <NftBox />
      ) : (
        <>
          <div className="p-4 mt-10 text-center py-6">
            <p>Connect your wallet to view your NFTs</p>
            <br />
            <Button rounded center onClick={connectHandler}>
              Connect Wallet
            </Button>
          </div>
        </>
      )}
      {/* <br />
      <hr />
      <br />
      <div className="bg-dark p-6 rounded-lg">
        <p className="text-2xl mb-6 font-bold">NFT information</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {lists.map((list, index) => (
            <div key={index}>
              <p className="mb-2 text-xl font-semibold">{list.title}</p>
              <ul className="grid gap-1">
                {list.items.map((val, i) => (
                  <li
                    key={i}
                    className="grid grid-flow-col gap-1 justify-start"
                  >
                    <span>👉</span>
                    <span>{val}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default MyNfts;
