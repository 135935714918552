import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { getNftById, getTraits } from "../../service";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import { BASE_URL, getNftUrl } from "../../utils";

const Nft = () => {
  const [loading, setLoading] = useState(false);
  const [nftData, setNftData] = useState({});
  const [traits, setTraits] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getTraits();
        console.log(data);
        if (!data.ok) return;
        setTraits(data.data.traits);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, []);

  const getValueCount = (trait_type = "Background", value = "Yellow") => {
    if (traits.length === 0) return 0;
    const filterdTrait = traits.filter((t) => t.trait_type === trait_type);
    // console.log(filterdTrait);
    if (filterdTrait.length === 0) return 0;
    const values = filterdTrait[0].values.filter((v) => v.value === value);
    if (values.length === 0) return 0;
    console.log(values);
    return values[0].count;
  };
  console.log();
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const data = await getNftById(id);
        console.log(data);
        if (!data.ok) return;
        setNftData(data.data.nft);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [id]);

  const array = [
    2349, 738, 567, 2692, 1296, 1347, 1063, 83, 800, 1731, 1547, 2300, 2178,
    2694, 1360, 1400, 279, 2623, 206, 1683,
  ];
  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="text-white container pb-10">
        <div className="mt-10 mx-auto w-full grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            {/* <img
              // src={`https://ipfs.io/ipfs/bafybeickm6g2642mptchejj7hodh4wqexoa6k5prwxt2csgvqay3vemhpm/${val.countId}.png`}
              src={getNftUrl(nftData.countId)}
              alt=""
              className="w-full rounded-xl "
            /> */}

            <LazyLoadImage
              alt={nftData.countId}
              height={"auto"}
              src={getNftUrl(nftData.countId)}
              width={"100%"}
              placeholderSrc="./assets/loader.webp"
              className="clip"
              // placeholder={
              //   <div className="bg-white bg-opacity-10 rounded-md w-full h-full animate-pulse" />
              // }
            />
            <p className="font-semibold my-2">Rank #{nftData.rank}</p>

            <p className="font-semibold">#{nftData.countId}</p>
            {/* <div>
              <a
                href="/"
                className=" grid grid-flow-col gap-2 text-xl justify-center items-center mt-6 bg-dark py-2 px-2 rounded-xl"
              >
                <img src="/assets/opensea.svg" className="w-8" alt="" />
                <p className="underline">View on TofuNFT</p>
              </a>
              <div className="grid grid-cols-1 gap-3 mt-3">
                <a
                  href={`${BASE_URL}/nft/get/${id}`}
                  target="_blank"
                  className="block text-center bg-dark py-2 px-2 rounded-xl"
                  rel="noreferrer"
                >
                  <p className="underline">JSON</p>
                </a>{" "}
             
              </div>
            </div> */}
          </div>
          <div>
            {/* <div className=" bg-primary bg-opacity-60 p-2 rounded-xl">
              <p className="text-center text-lg">Rarity Score </p>
              <div className="bg-primary bg-opacity-70 p-2 rounded-xl py-3 text-center mt-2 text-xl">
                {nftData?.total_rarity_score?.toFixed(2)}
              </div>
            </div> */}
            <div className="grid grid-flow-col gap-2 items-center  justify-center text-2xl font-bold">
              <p className="text-center ">Rarity Score: </p>
              <p> {nftData?.total_rarity_score?.toFixed(2)}</p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
              {nftData?.attributes?.map((val, i) => (
                <div key={i}>
                  <div className="flex justify-between items-center">
                    <p className="  capitalize">{val.trait_type}</p>
                    {!array.includes(nftData.countId) && (
                      <p className="text-primary">
                        {" "}
                        +{Number(val.rarity_score).toFixed(2)}
                      </p>
                    )}
                  </div>
                  <div className="bg-dark p-2 rounded-xl mt-2 flex justify-between items-center">
                    <p className="capitalize">{val.value}</p>
                    {!array.includes(nftData.countId) && (
                      <div className="  px-2 py-1 rounded-lg min-w-[60px] text-center ">
                        {Number(
                          getValueCount(val.trait_type, val.value)
                        ).toFixed(2)}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nft;
