const Eth = "/assets/icon/ethereum.svg";
const BNB = "/assets/icon/binance.svg";
const POLYGON = "/assets/icon/polygon.svg";
// const OPTIMISM = "/assets/icon/optimism.jpg";
// const GNOSIS = "/assets/icon/gnosis.svg";

export const chains = [
  // {
  //   icon: Eth,
  //   name: "Goreli",
  //   symbol: "ETH",
  //   chain: 5,
  //   disabled: false,
  // },
  {
    icon: Eth,
    name: "Ethereum",
    symbol: "ETH",
    chain: 1,
    disabled: false,
  },
  {
    icon: BNB,
    name: "Binance",
    symbol: "BNB",
    chain: 56,
    disabled: false,
  },
  // {
  //   icon: BNB,
  //   name: "Binance T",
  //   symbol: "BNB",
  //   chain: 97,
  //   disabled: false,
  // },
  // {
  //   icon: POLYGON,
  //   name: "Polygon T",
  //   chain: 80001,
  //   // disabled: true,
  // },
  // {
  //   icon: OPTIMISM,
  //   name: "Optimism",
  //   chain: 97,
  //   disabled: true,
  // },
  // {
  //   icon: GNOSIS,
  //   name: "Gnosis Chain",
  //   chain: 97,
  //   disabled: true,
  // },
];
