import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import Web3 from "web3";
import { WalletModalProvider } from "./context/walletModalContext";
import store from "./store";
import { Provider } from "react-redux";
import { Web3ReactProvider } from "@web3-react/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ChainChangeProvider from "./context/chainChangeContext";
const theme = createTheme({
  palette: {
    primary: {
      main: "#04fce8",
    },
  },
});
const getLibrary = (provider) => new Web3(provider);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <ThemeProvider theme={theme}>
            <WalletModalProvider>
              <ChainChangeProvider>
                <App />
              </ChainChangeProvider>
            </WalletModalProvider>
          </ThemeProvider>
        </Web3ReactProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);
