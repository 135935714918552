import React, { useEffect, useState } from "react";
import {
  claim,
  formatFromWei,
  formatFromWei2,
  getContract,
  getTokenDetails,
  unStake,
} from "../../../web3/helpers";
import ReactTimeago from "react-timeago";
import Timer from "../../../components/Timer";
import { useWeb3React } from "@web3-react/core";
import Button from "../../../components/Button";
import { masterAbis, masterAddresses } from "../../../web3/contracts/master";
import { CHAINLIST, getMostRareNFT, getRarityString } from "../../../utils";
const IndividualStakeDetailBox = ({ stake, address, abi }) => {
  const { account, library, chainId } = useWeb3React();
  const [unclaimedBalance, setUnclaimedBalance] = useState(0);
  const [tokenDetails, setTokenDetails] = useState({});
  const [rewardTokenDetails, setRewardTokenDetails] = useState({});
  const [nftRarity, setNftRarity] = useState(0);
  const [apy, setApy] = useState(0);
  const [stakedNFTs, setStakedNFTs] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getData = async () => {
      if (account) {
        const { ok, contract } = await getContract(library, address, abi);
        const { contract: masterContract } = await getContract(
          library,
          masterAddresses[chainId],
          masterAbis[chainId]
        );
        if (!ok) {
          return;
        }
        try {
          // setLoading(true);
          const baseTokenAddress = await contract.methods
            .baseTokenAddress()
            .call();
          const rewardTokenAddress = await contract.methods
            .rewardTokensAddress()
            .call();
          const rewardTokenDetails = await getTokenDetails(
            library,
            rewardTokenAddress
          );

          setRewardTokenDetails(rewardTokenDetails);
          const tokenDetails = await getTokenDetails(library, baseTokenAddress);
          setTokenDetails(tokenDetails);
          // console.log(rewardTokenDetails, tokenDetails);
          // console.log("calling...1");

          if (chainId === CHAINLIST.BSCT || chainId === CHAINLIST.BSC) {
            // console.log("calling...");
            const nftRarity = await masterContract.methods
              .nftRarities(stake.nftTokenId)
              .call();
            setNftRarity(nftRarity);
            const apy = await contract.methods
              .getAPY(nftRarity, Number(stake.lockDuration))
              .call();
            setApy(apy);
          } else {
            // console.log("calling");
            const stakedNfts = await contract.methods
              .getStakedNFTs(stake.id)
              .call();
            setStakedNFTs(stakedNfts);
            console.log(stakedNfts);
            const apy = await contract.methods
              .getAPY(nftRarity, Number(stake.lockDuration))
              .call();
            setApy(apy);
          }
          const unclaimedBalance = await contract.methods
            .getUnclaimedRewards(stake.id)
            .call();
          console.log("UNCLAIMED BALANCE", unclaimedBalance);
          setUnclaimedBalance(
            formatFromWei2(unclaimedBalance, rewardTokenDetails.decimals)
          );
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      }
    };
    getData();
  }, [abi, account, address, chainId, library, nftRarity, stake]);

  useEffect(() => {
    const getData = async () => {
      if (stakedNFTs.length === 0) return;
      try {
        const mostRare = await getMostRareNFT(stakedNFTs);
        console.log(mostRare);
        setNftRarity(mostRare?.rarity_value);
      } catch (error) {
        console.log(error);
      }
    };

    getData();
  }, [stakedNFTs]);
  const status = Number(stake.status);
  const isActive = status === 1;
  const isLastClaimed = Number(stake.lastClaimedTime) > 0;
  let isAbletoUnstake = Number(stake.unlockTime) * 1000 - Date.now();
  isAbletoUnstake = isAbletoUnstake < 0;
  // console.log(stake);
  // console.log(nftRarity);
  return loading ? (
    <div className=" bg-[#131313] p-4 rounded-md grid grid-cols-1 gap-2 text-sm animate-pulse"></div>
  ) : (
    <div className=" bg-[#131313] p-4 rounded-md grid grid-cols-1 gap-2 text-sm">
      <div className="flex-between">
        <p>Stake Id</p>
        <p className="mt-1">#{stake.id}</p>
      </div>{" "}
      {chainId === CHAINLIST.BSCT || chainId === CHAINLIST.BSC ? (
        <div className="flex-between">
          <p>Staked NFT</p>
          <p className="mt-1">#{stake.nftTokenId}</p>
        </div>
      ) : (
        <div className="flex-between">
          <p>Staked NFTs</p>
          <p className="mt-1">
            {stakedNFTs.map((nft, i) => (
              <span>
                #{nft}
                {stakedNFTs.length - 1 !== i && ","}
              </span>
            ))}
          </p>
        </div>
      )}
      <div className="flex-between">
        <p>Rarity</p>
        <p className="mt-1">{getRarityString(+nftRarity)}</p>
      </div>{" "}
      <div className="flex-between">
        <p>APY</p>
        <p className="mt-1">{apy}%</p>
      </div>{" "}
      <div className="flex-between">
        <p>Staked</p>
        <p className="mt-1">
          {formatFromWei2(stake.stakeBalance, tokenDetails.decimals)}{" "}
          <span className="md:inline-block hidden"> {tokenDetails.symbol}</span>
        </p>
      </div>{" "}
      <div className="flex-between">
        <p>Status</p>
        <p className="text-xs">
          {isActive ? (
            <span className=" active-batch">Active</span>
          ) : (
            <span className=" unstaked-batch">Unstaked</span>
          )}
        </p>
      </div>
      <div className="flex-between">
        <p className="">Total Claimed </p>
        <p>
          {formatFromWei2(
            stake.totalClaimedRewards,
            rewardTokenDetails.decimals
          )}{" "}
          <span className="md:inline-block hidden"> {tokenDetails.symbol}</span>
        </p>
      </div>{" "}
      <div className="flex-between">
        <p className=""> Unclaimed Rewards</p>
        <p className="md:inline-block hidden">
          {isActive ? unclaimedBalance + " " + tokenDetails.symbol : "__"} {}
        </p>{" "}
        <p className="md:hidden">
          {isActive ? unclaimedBalance : "__"} {}
        </p>
      </div>{" "}
      <div className="flex-between">
        <p>Stake Duration</p>
        <p className="mt-1">{stake.lockDuration} Days</p>
      </div>{" "}
      <div className="flex-between">
        <p className="">Last Claimed </p>
        <p>
          {isActive && isLastClaimed ? (
            <ReactTimeago date={Number(stake?.lastClaimedTime) * 1000} />
          ) : (
            "_ _"
          )}
        </p>
      </div>{" "}
      {/* <div className="flex-between">
        <p className="">Staked At</p>
        <p>
          <ReactTimeago date={stake.createdTime * 1000} />
        </p>
      </div>{" "} */}
      <div className="flex-between">
        <p className="">Unlock in</p>
        <p>{isActive ? <Timer timestamp={stake.unlockTime} /> : "_ _"}</p>
      </div>{" "}
      <div className="grid grid-cols-2 gap-2 mt-4">
        <Button
          disable={!isActive || !isAbletoUnstake || +unclaimedBalance === 0}
          sm
          onClick={() => claim(library, stake.id, address, abi)}
          fullWidth
        >
          Claim
        </Button>
        <Button
          fullWidth
          disable={!isActive}
          sm
          onClick={() => unStake(library, stake.id, address, abi)}
        >
          UnStake
        </Button>
      </div>
    </div>
  );
};

export default IndividualStakeDetailBox;
