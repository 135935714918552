/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
// import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// import { approveNft, approveToken } from "../web3/helpers";
import { useWeb3React } from "@web3-react/core";
import {
  approveForAllNft,
  approveNft,
  approveToken,
  getContract,
  stake,
} from "../web3/helpers";
import Button from "./Button";
import { NFT_Abi, NFT_Address, NFT_Addresses } from "../web3/contracts/nft";
import { tokenAbi } from "../web3/contracts/token";
import { toast } from "react-hot-toast";
import { CHAINLIST } from "../utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 500,
  width: "90%",
  bgcolor: "#262626",
  zIndex: 10000000000000,
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};
export default function StakeModal({
  open,
  handleClose,
  selectedNft,
  selectedNFTs,
  poolId,
  value,
  tokenAddress,
  address,
  abi,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const { library, account, chainId } = useWeb3React();
  // useEffect(() => {
  //   const getData = async () => {
  //     const { ok, contract: nftContract } = await getContract(
  //       library,
  //       NFT_Addresses[chainId],
  //       NFT_Abi
  //     );

  //     const { ok: tokenOk, contract: tokenContract } = await getContract(
  //       library,
  //       tokenAddress,
  //       tokenAbi
  //     );
  //     // console.log(nftId, contractAddress);
  //     if (!ok) {
  //       return;
  //     }
  //     try {
  //       const isApprovedForAll = await nftContract.methods
  //         .isApprovedForAll(account, address)
  //         .call();
  //       console.log(isApprovedForAll);
  //       if (isApprovedForAll) {
  //         setActiveStep(1);
  //       }

  //       const totalSupply = await tokenContract.methods
  //         .allowance(account, address)
  //         .call();
  //       if (+value <= +totalSupply) {
  //         setActiveStep(2);
  //       }
  //       console.log(totalSupply);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getData();
  // }, [account, address, chainId, library, tokenAddress, value]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const steps = [
    {
      label: "Approve NFT",
      body: (
        <Button
          sm
          onClick={() =>
            approveNft(
              library,
              selectedNft,
              address,
              NFT_Addresses[chainId],
              handleNext
            )
          }
        >
          Approve NFT
        </Button>
      ),
    },
    {
      label: "Approve Tokens",
      body: (
        <Button
          sm
          onClick={() =>
            approveToken(library, value, tokenAddress, address, handleNext)
          }
        >
          Approve Token
        </Button>
      ),
    },
    {
      label: "Stake",
      body: (
        <Button
          sm
          onClick={() =>
            stake(
              library,
              poolId,
              selectedNft,
              selectedNFTs,
              value,
              address,
              abi,
              tokenAddress,
              chainId,
              handleClose
            )
          }
        >
          Stake
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-white text-center ">
            <div className="text-xl  font-bold mb-10 ">
              Follow these steps to Stake Your NFT
            </div>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps
                .filter((val) => {
                  if (
                    chainId === CHAINLIST.POLYGONT ||
                    chainId === CHAINLIST.ETH
                  ) {
                    return val.label !== "Approve NFT";
                  }
                  return val;
                })
                .map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>
                      <p className="text-white">{step.label}</p>
                    </StepLabel>
                    <StepContent>
                      <p className="text-white">{step.description}</p>
                      <Box sx={{ mb: 2 }}>
                        {step.body}
                        {/* <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {step.label}
                        </Button>
                      </div> */}
                      </Box>
                    </StepContent>
                  </Step>
                ))}
            </Stepper>
            {/* {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
              </Paper>
            )} */}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
