import React from "react";

const RaritySelect = ({ data, selectedTraits, setSelectedTraits }) => {
  return (
    <div>
      <p className="text-lg capitalize">{data.trait_type}</p>
      {/* <Select options={options} className="text-black text-sm" /> */}
      <select
        name=""
        id=""
        className="w-full  text-black rounded-md focus:ring-0"
        value={selectedTraits[data.trait_type]}
        onChange={(e) => {
          setSelectedTraits((prev) => ({
            ...prev,
            [data.trait_type]: e.target.value,
          }));
        }}
      >
        <option value=""></option>
        {data.values.map((option) => (
          <option value={`${data.trait_type}_${option.value}`}>
            {option.value} {option.count}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RaritySelect;
