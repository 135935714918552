import React from "react";
import SearchBox from "../../components/SearchBox";

const Wallet = () => {
  return (
    <div className="container">
      <div className=" mt-10 mb-20 text-white">
        <h1 className="text-5xl font-bold   text-center mt-20 block text-primary">
          SHIBELON NFTS
        </h1>
        <p className="text-2xl font-semibold text-center mt-8 mb-4">
          Check Rarity In Your Wallet
        </p>
        <SearchBox />
        {/* <div className="grid grid-flow-col gap-2 justify-center items-center">
      <button
        onClick={() => setFilterOpen(true)}
        className="bg-primary px-10 w-full rounded-full py-2 text-black"
      >
        Trait Filter (0)
      </button>
      <select name="" id="" className="text-black rounded-full ">
        <option value="">Sort by Rarity</option>
        <option value="">Sort by Id</option>
      </select>
    </div> */}
      </div>
    </div>
  );
};

export default Wallet;
