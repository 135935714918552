import React from "react";

const StakeSummary = () => {
  return (
    <div className="text-white p-4">
      <div className="bg-dark p-4 rounded-lg py-10">
        <h2 className="text-center text-2xl font-bold">Summary</h2>
        <div className="text-center grid grid-cols-1 md:grid-cols-2 gap-2 max-w-[500px] mx-auto">
          <div className=" mx-auto w-full mt-10">
            <p>Total Shibelon tokens staked</p>
            <p>XXX equivalent to $XXX</p>
          </div>{" "}
          <div className=" mx-auto w-full mt-10">
            <p>Total Shibelon tokens staked</p>
            <p>XXX equivalent to $XXX</p>
          </div>
        </div>
      </div>{" "}
      <div className="bg-dark p-4 rounded-lg py-10 mt-10">
        <h2 className="text-center text-2xl font-bold">My Pools</h2>
        <div className="text-center grid grid-cols-1 md:grid-cols-2 gap-2 max-w-[500px] mx-auto">
          {/* <div className=" mx-auto w-full mt-10">
            <p>Total Shibelon tokens staked</p>
            <p>XXX equivalent to $XXX</p>
          </div>{" "}
          <div className=" mx-auto w-full mt-10">
            <p>Total Shibelon tokens staked</p>
            <p>XXX equivalent to $XXX</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default StakeSummary;
