import Home from "./screens/Home";
import { Routes, Route } from "react-router-dom";

import { Toaster } from "react-hot-toast";

import Layout from "./components/Layout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Reward from "./screens/Reward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MyNfts from "./screens/MyNfts";
import StakeSummary from "./screens/Summary";
import Rarity from "./screens/Rarity";
import Matrix from "./screens/Matrix";
import Wallet from "./screens/Wallet";
import Nft from "./screens/Nft";
import InjectionSupply from "./screens/InjectionSupply";
import Mint from "./screens/Mint";
function App() {
  const queryClient = new QueryClient();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#c22026",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <Toaster containerClassName="text-sm" />
          <Routes>
            <Route element={<Home />} index />
            {/* <Route element={<Reward />} path="/rewards" /> */}
            <Route element={<MyNfts />} path="/my_nfts" />
            <Route element={<StakeSummary />} path="/stake_summary" />
            <Route element={<Rarity />} path="/rarity" />
            <Route element={<Matrix />} path="/matrix" />
            <Route element={<Wallet />} path="/wallet" />
            <Route element={<Nft />} path="rarity/nft/:id" />
            <Route
              element={<InjectionSupply />}
              path="inject_reward/:contractAddress/:tokenAddress"
            />{" "}
            <Route element={<Mint />} path="/mint" />
          </Routes>
        </Layout>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
