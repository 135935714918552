import { Collapse } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import Button from "../../../components/Button";
import AllStakes from "./AllStakes";
import Stake from "./Stake";

const Pool = ({ data, address, abi, userStakes, apy }) => {
  const [open, setOpen] = useState(false);
  const [selectedStake, setSelectedStake] = useState([]);

  useEffect(() => {
    if (userStakes && userStakes.length > 0) {
      const selectedStake = userStakes.filter(
        (val) => Number(val.lockDuration) === Number(data.duration)
      );
      // console.log(selectedStake);
      setSelectedStake(selectedStake);
    }
  }, [data, userStakes]);

  return (
    <div className=" bg-dark-400 p-4 rounded-lg">
      <div className="grid grid-cols-1 text-center gap-4 md:flex justify-between items-center">
        <p>
          {data.duration} Days Pool{" "}
          {/* <span className=" hidden md:inline-block">
            (
            {account &&
              `Stakable NFT : #${holdingNfts}, Rarity:
          ${getRarityString(nftRarity)}, `}
            APY: {apy})
          </span> */}
        </p>
        <div>
          <Button fullWidth sm onClick={() => setOpen(!open)}>
            <span className="">View & </span> Stake
          </Button>
        </div>
      </div>
      <Collapse in={open && address}>
        <div>
          <Stake
            data={data}
            address={address}
            abi={abi}
            stakeData={selectedStake}
          />
          {selectedStake?.length > 0 && (
            <AllStakes userStakes={selectedStake} address={address} abi={abi} />
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default Pool;
