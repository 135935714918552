import axios from "axios";
import { BASE_URL } from "../utils";

export const getNfts = async (page, sort, traits, search) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/nft/all?page=${page}&sort=${sort}&traits=${traits}&search=${search}`
    );
    return data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};

export const getNftById = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/nft/get/${id}`);
    return data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};
export const getTraits = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/traits/all`);
    return data;
  } catch (error) {
    console.log(error.message);
    return null;
  }
};
